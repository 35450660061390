import Fuse from 'fuse.js';
import { SelectorEngine } from './utils/SelectorEngine';

const SELECTORS = {
	items: (key) => `[data-filter-item="${key}"]`,
	clearButton: '[data-filter-clear]',
};

const ATTRIBUTES = {
	key: 'data-filter',
	debounce: 'data-filter-debounce',
	searchString: 'data-filter-value',
	weight: 'data-filter-weight',
};

const CLASSES = {
	showEmptyState: 'show-empty-state',
};

export class FuzzySearchInput {
	constructor(input) {
		if (!input) return;

		this.input = SelectorEngine.getElement(input);

		// eslint-disable-next-line prefer-destructuring
		this.clearButton = SelectorEngine.next(this.input, SELECTORS.clearButton)[0];

		this.bindEvents();
		this.setup();
	}

	/**
	 * Bind `this` explicitly for event listeners.
	 */
	bindEvents() {
		['onChange', 'onClear'].forEach((e) => {
			this[e] = this[e].bind(this);
		});
	}

	onChange() {
		const value = this.input.value || '';

		// If value is empty show all items.
		if (value === '') {
			this.hideClearButton();
			this.hideEmptyState();

			this.items.forEach((item) => {
				item.style.display = 'flex';
				item.style.removeProperty('order');
			});
			return;
		}

		const found = this.fuse.search(value).map((e) => e.item.el);
		if (found.length < 1) this.showEmptyState();
		else this.hideEmptyState();

		this.showClearButton();

		this.items.forEach((item) => {
			if (found.includes(item)) {
				item.style.order = found.indexOf(item);
				item.style.display = 'flex';
			} else {
				item.style.display = 'none';
			}
		});
	}

	onClear() {
		this.input.value = '';
		this.onChange();
	}

	setupEventListeners() {
		this.input.addEventListener('input', this.onChange);
		this.clearButton?.addEventListener('click', this.onClear);
	}

	hideClearButton() {
		if (!this.clearButton) return;
		this.clearButton.style.display = 'none';
	}

	showClearButton() {
		if (!this.clearButton) return;
		this.clearButton.style.removeProperty('display');
	}

	hideEmptyState() {
		const parent = this.items?.[0]?.parentElement;
		parent?.classList.remove(CLASSES.showEmptyState);
	}

	showEmptyState() {
		const parent = this.items?.[0]?.parentElement;
		parent?.classList.add(CLASSES.showEmptyState);
	}

	/**
	 * Pull all the config from data attributes on the input.
	 */
	getConfig() {
		const el = this.input;
		// Get the weight from attribute
		const weight = el.getAttribute(ATTRIBUTES.weight);
		// Parse weight
		this.searchKeys = FuzzySearchInput._parseWeight(weight);

		this.itemsKey = el.getAttribute(ATTRIBUTES.key);
	}

	/**
	 * Get items based on the `itemsKey` and generate a search list.
	 * A search list will have the actual HTML element and the `searchString`.
	 */
	getItems() {
		this.items = [...document.querySelectorAll(SELECTORS.items(this.itemsKey))];

		this.searchList = this.items.map((item) => {
			let search = '';
			try {
				search = JSON.parse(item.getAttribute(ATTRIBUTES.searchString));
			} catch (e) {
				console.error(e);
				search = item.getAttribute(ATTRIBUTES.searchString);
			}
			return {
				el: item,
				search,
			};
		});
	}

	initSearch() {
		const searchIndex = Fuse.createIndex(this.searchKeys, this.searchList);

		this.fuse = new Fuse(
			this.searchList,
			{
				keys: this.searchKeys,
				includeScore: true,
			},
			searchIndex
		);
	}

	setup() {
		this.getConfig();
		this.getItems();
		this.initSearch();
		this.hideClearButton();

		this.setupEventListeners();
	}

	/**
	 * Parse the weight passed to the data attribute.
	 * When the weight is passed it is a JSON string that contains object where
	 * the key is the name of the property and value is the weight.
	 *
	 * Weight values need to be `0` and `1`.
	 *
	 * Because our list that is being search holds all the values under `search` property
	 * all the search keys need to be nested by providing a path with dot notation.
	 *
	 * @see {@link https://fusejs.io/examples.html#nested-search}
	 * @example
	 * _parseWeight(`
	 * 		{
	 * 		 "title": "1",
	 * 		 "author": "0.2"
	 * 		}
	 * `)
	 * // => [
	 * 		{ name: 'search.title', weight: 1 },
	 * 		{ name: 'search.author', weight: 0.2 },
	 * 	]
	 *
	 * @param {string|null} weight JSON string with name and value.
	 * @param searchKey Key in the list that is used for `searchString`.
	 * @returns {{name: string, weight: number}[]|string[]}
	 * @private
	 */
	static _parseWeight(weight, searchKey = 'search') {
		if (!weight) return [searchKey];
		try {
			const weightObj = JSON.parse(weight);
			return Object.keys(weightObj).map((key) => ({
				name: [searchKey, key].join('.'),
				weight: Number(weightObj[key]),
			}));
		} catch (e) {
			return [searchKey];
		}
	}
}
