import Glide, { Breakpoints, Controls, Swipe, Images } from '@glidejs/glide/dist/glide.modular.esm';
import { getDir } from '../../common/components/utils/utils';

const sliderInstances = [];

/**
 * When tabs change on the homepage update the countries sliders.
 * Because they are hidden and styles can't be calculated.
 */
export function onTabsChange(id) {
	if (id === 'countries') sliderInstances.forEach((slider) => slider.update());
}

function initCountriesSlider() {
	const countrySliders = [...document.querySelectorAll('[data-slider-home-countries]')];

	countrySliders.forEach((slider) => {
		sliderInstances.push(
			new Glide(slider, {
				perView: 2.8,
				gap: 32,
				rewind: false,
				direction: getDir(),
				breakpoints: {
					600: {
						perView: 1.2,
						gap: 24,
					},
				},
			}).mount({ Controls, Breakpoints, Swipe, Images })
		);
	});
}

export function init() {
	console.info('Initializing `Home`');
	initCountriesSlider();
}
