const SELECTORS = {
	navigationDropdownOpener: '.navigation-dropdown-opener',
	navigationDropdown: '.navigation__content--desktop__dropdown',
};
const CLASSES = {
	active: 'active',
};
export class NavigationDropdown {
	constructor() {
		this.navigationDropdownOpeners = document.querySelectorAll(SELECTORS.navigationDropdownOpener);
		this.navigationDropdowns = document.querySelectorAll(SELECTORS.navigationDropdown);
		const requiredElements = [
			this.navigationDropdowns.length,
			this.navigationDropdownOpeners.length,
		];
		if (requiredElements.some((el) => !el)) {
			// throw new Error('${COMPONENT_NAME} not initialized');
			// or
			return;
		}
		this.bindEvents();
		this.setup();
	}

	static init() {
		// eslint-disable-next-line no-unused-vars
		const navigationDropdown = new NavigationDropdown();
	}

	/**
	 * Bind `this` explicitly for event listeners.
	 */
	bindEvents() {
		['hide', 'show'].forEach((e) => {
			this[e] = this[e].bind(this);
		});
	}

	hide(e) {
		const isInDropdown = e.target.closest(SELECTORS.navigationDropdown);
		if (!isInDropdown) {
			[...this.navigationDropdownOpeners].forEach((el) => el.classList.remove(CLASSES.active));
			[...this.navigationDropdowns].forEach((el) => el.classList.remove(CLASSES.active));
		}
	}

	show(e) {
		const arrNavigationDropdowns = [...this.navigationDropdowns];
		e.preventDefault();
		if (!e.target.classList.contains(CLASSES.active)) {
			const targetDataId = e.target.dataset.id;
			this.hide(e);
			const dropdownToOpen = arrNavigationDropdowns.find((el) => el.dataset.id === targetDataId);
			e.target.classList.add(CLASSES.active);
			dropdownToOpen.classList.add(CLASSES.active);
			e.stopPropagation();
		}
	}

	/**
	 * Setup all event listeners used by the component.
	 */
	setupEventListeners() {
		window.addEventListener('click', this.hide);
		this.navigationDropdownOpeners.forEach((e) => e.addEventListener('click', this.show));
	}

	setup() {
		this.setupEventListeners();
	}
}
