<script>
import { useOfflineBookingStore } from '../../../common/components/offline-booking/store';
import VSingleTreatment from './VSingleTreatment';
import VWeeklyTreatment from './VWeeklyTreatment';

export default {
    components: {VWeeklyTreatment, VSingleTreatment},
    props: {
        shifts: {
            type: Object,
            required: false,
        },
        dayOrder: {
            type: Array,
            required: false,
        },
        shiftSelectionPlaceholder: {
			type: String,
			required: false,
		},
    },
    setup() {
        const store = useOfflineBookingStore();

        return {
            store,
        }
    }
}
</script>
<template>
    <div>
        <div class="flex-center mb-32">
            <label class="radio">
                <input
                    type="radio"
                    name="type"
                    value="single"
                    :checked="store.isSingleTreatment"
                    @change="store.changeTemplate('single')">
                <span><slot name="label-single-treatment"/></span>
            </label>

            <label class="radio form-control ml-16">
                <input
                    type="radio"
                    name="type"
                    value="weekly"
                    :checked="store.isWeeklyTreatment"
                    @change="store.changeTemplate('weekly')">
                <span><slot name="label-weekly-treatment"/></span>
            </label>
        </div>

        <label class="input-label mb-8" for="single-treatment-calendar-range"><slot name="title-treatment-date"/></label>
        <v-single-treatment :shifts="shifts" v-if="store.isSingleTreatment" :placeholder="shiftSelectionPlaceholder">
            <template v-for="(_, slot) of $slots" v-slot:[slot]="scope"><slot :name="slot" v-bind="scope"/></template>
        </v-single-treatment>

        <v-weekly-treatment :shifts="shifts" v-if="store.isWeeklyTreatment" :dayOrder="dayOrder" :placeholder="shiftSelectionPlaceholder">
            <template v-for="(_, slot) of $slots" v-slot:[slot]="scope"><slot :name="slot" v-bind="scope"/></template>
        </v-weekly-treatment>
    </div>
</template>
