<template>
	<div>
		<!-- working days -->
		<div class="time-range-section split-section">
			<div class="split-section__content">
				<p class="mt-0 mb-8 fw-500">Working days</p>
				<div class="group-small">
					<button
						type="button"
						v-for="(days, index) in listOfDays"
						:key="days"
						:data-id-range="index"
						class="day-filter-button"
						:class="{ active: days.active }"
						@click="showTimeRange(days, index)"
					>
						{{ days.dayName }}
					</button>
				</div>
			</div>
		</div>
		<!-- ./ working days -->
		<!-- time range per day -->
		<div
			v-for="item in activeDays"
			:key="item"
			class="time-range-section split-section"
		>
			<div class="split-section__content">
				<p class="heading-4">{{ item.dayNameFull }}</p>
				<div class="split-section__append">
					<div
						v-for="(time, from) in item.timeShifts"
						:key="time"
						class="group-input--inline"
					>
						<div class="group-input--item">
							<div class="icon-wrapper has-icon mb-8">
								<label :for="`from-${item.dayName}-${from}`">
									{{ props.textFrom }}
								</label>
							</div>
							<div class="input-with-icon">
								<input
									:id="`from-${item.dayName}-${from}`"
									class="input input--primary"
									type="time"
									:name="`hours[${item.dayInputName}][${from}][from]`"
									v-model="time.from"
									required
								/>
								<svg class="icon input-with-icon__suffix" role="img">
									<use xlink:href="#icon-clock"></use>
								</svg>
							</div>
						</div>
						<div class="group-input--item">
							<div class="icon-wrapper has-icon mb-8">
								<label :for="`to-${item.dayName}-${from}`">
									{{ props.textTo }}
								</label>
							</div>
							<div class="input-with-icon">
								<input
									:id="`to-${item.dayName}-${from}`"
									class="input input--primary"
									type="time"
									:name="`hours[${item.dayInputName}][${from}][to]`"
									v-model="time.to"
									required
								/>
								<svg class="icon input-with-icon__suffix" role="img">
									<use xlink:href="#icon-clock"></use>
								</svg>
							</div>
							<button
								type="button"
								class="group-input--remove"
								@click="removeNewRange(item.timeShifts, time)"
							>
								<svg class="icon input-with-icon__suffix" role="img">
									<use xlink:href="#icon-close"></use>
								</svg>
							</button>
						</div>
					</div>
				</div>
				<button
					type="button"
					class="button button--underline button--small mt-16"
					@click="addNewRange(item)"
				>
					{{ props.textAddNewRange }}
				</button>
			</div>
		</div>
		<!-- ./time range per day -->
	</div>
</template>
<script setup>
import { ref, computed } from 'vue';
const props = defineProps({
	openHoursList: { type: Array, required: true },
	textTo: {
		type: String,
		required: false,
		default: 'To',
	},
	textFrom: {
		type: String,
		required: false,
		default: 'From',
	},
	textAddNewRange: {
		type: String,
		required: false,
		default: 'Add New Range',
	},
});
const listOfDays = ref(props.openHoursList);
//make a fixed values for days for name iputs
const listDaysInWeek = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
];
listOfDays.value.forEach((item, index) => {
    item['dayInputName'] = listDaysInWeek[index];
});
const activeDays = computed(() => {
	return listOfDays.value.filter((a) => a.active);
});
const showTimeRange = (item, index) => {
	listOfDays.value[index].active = !listOfDays.value[index].active;
	if (!listOfDays.value[index].active) {
		item.timeShifts = [];
	} else if (item.timeShifts.length === 0) {
		item.timeShifts.push({ from: '', to: '' });
	}
};
const addNewRange = (item) => {
	item.timeShifts.push({ from: '', to: '' });
};
const removeNewRange = (item, time) => {
	item.splice(
		item.findIndex((e) => e.from === time.from),
		1
	);
};
</script>
