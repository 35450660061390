<script>
import {computed, ref, unref, watch, nextTick} from 'vue';
import {debounce, isFunction, isObject} from '../../common/components/utils/utils';

export default {
    name: 'VSelectSearch',
    components: {},
    props: {
        id: {
            type: String,
            required: false,
        },
        required: {
            type: Boolean,
            required: false,
            default: false,
        },
        /**
         * Select input name.
         */
        name: {
            type: String,
            required: false,
        },
        /**
         * Initial data to populate the select
         */
        data: {
            type: Array,
            required: true,
        },
        /**
         * Provide a data source to fetch the results.
         * If the value is set to false the search will be done in-place
         * with existing data.
         */
        search: {
            type: [Function, String, Boolean],
            required: false,
            default: false,
        },
        /**
         * If API is not used as a source search value is required.
         * This is the property used to search inside the object.
         */
        searchProperty: {
            type: String,
            required: false,
            default: 'name',
        },
        /**
         * Value of the selected object that will be used when submitting
         * the form.
         */
        valueProperty: {
            type: String,
            required: false,
            default: 'id',
        },
        /**
         * Input placeholder.
         */
        placeholder: {
            type: String,
            default: 'Search',
        },
        /**
         * Search Input placeholder.
         */
        searchPlaceholder: {
            type: String,
            default: 'Search',
        },
        /**
         * If the input is disabled or not
         */
        disabled: {
            type: Boolean,
            default: false,
        },
        /**
         * Additional request headers.
         */
        requestParams: {
            type: Object,
        },
        /**
         * Minimum length of the search string before search is done.
         */
        minLength: {
            type: Number,
            default: 0,
        },
        /**
         * Time to debounce typing before sending the request.
         */
        debounceTime: {
            type: Number,
            default: 300,
        },
        /**
         * Transformer function to run the results through.
         * Useful if you want to additionally transform the result.
         */
        transformer: {
            type: Function,
        },
        /**
         * Preselected the value in data.
         * If a string is given it will match it with `valueProperty` in data.
         * If an object is given it will directly add it as a selected item.
         */
        selected: {
            type: [String, Object, Number],
            required: false,
        },
        /**
         * On dropdown open scroll the selected option into view.
         */
        scrollIntoView: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    setup(props, {emit}) {
        /*************************************************
         * HTML Refs
         **************************************************/
        const rootElement = ref(null);
        const dropdownListElement = ref(null);
        const searchInputElement = ref(null);
        const inputWrapperElement = ref(null);
        const selectElement = ref(null);

        /*************************************************
         * State
         **************************************************/
        const data = ref(props.data);
        const isLoading = ref(false);
        const isFocused = ref(false);

        const isDropdownOpen = ref(false);
        const hasEventListeners = ref(false);

        const results = ref([]);
        const numberOfResults = computed(() => results.value.length);

        const selectedItemIndex = ref(-1);
        const selectedItem = ref({});
        const hasSelectedItem = computed(() => Object.keys(selectedItem.value).length)
        const selectedItemValue = computed(() => selectedItem.value[props.valueProperty]);
        const selectedItemPlaceholder = computed(() => selectedItem.value[props.searchProperty]);
        const isItemSelected = (index) => (isDropdownOpen.value && index === selectedItemIndex.value);

        const isEmpty = computed(() => !numberOfResults.value && !isLoading.value && isFocused.value)

        /**
         * Get search value from the data object, based on search property.
         */
        const getSearchValue = (object) => object[props.searchProperty].toLowerCase();

        /*************************************************
         * Init
         **************************************************/

        const findSelectedValue = () => {
            if (!props.selected) return;
            if (isObject(props.selected)) {
                selectedItemIndex.value = data.value.findIndex(o => o[props.valueProperty] === props.selected[props.valueProperty])
            } else {
                selectedItemIndex.value = data.value.findIndex(o => o[props.valueProperty] === props.selected)
            }
            if (selectedItemIndex.value !== -1) selectedItem.value = data.value[selectedItemIndex.value]
        }

        watch(() => props.selected, () => findSelectedValue());
        watch(data, (value) => {
            results.value = value;
            findSelectedValue();
        }, { immediate: true })

        /*************************************************
         * Component Events
         **************************************************/

        const triggerChange = () => emit('change', selectedItem.value);

        watch(selectedItem, triggerChange);

        /*************************************************
         * Search
         **************************************************/

        const searchInput = ref('');
        const isInSearchMode = computed(() => searchInput.value.length);
        const searchDisabled = computed(() => props.search === false);
        const searchQuery = computed(() => searchInput.value.toLowerCase());
        const hasMinSearchLength = computed(() => searchInput.value.length >= props.minLength);

        //------- In-place Search

        /**
         * Check if the search query string is part of the item.
         * @param item
         * @return {*}
         */
        const isQueryInItem = (item) => getSearchValue(item).includes(searchQuery.value);

        function inPlaceSearch() {
            if (!searchQuery.value) results.value = data.value;
            else results.value = data.value.filter(isQueryInItem)
        }

        // On result change reset the selected index
        watch(results, () => selectedItemIndex.value = 0)

        /*************************************************
         * Methods
         **************************************************/

        const clickOutsideListener = (event) => {
            if (!rootElement.value?.contains(event.target)) closeDropdown();
        }

        const addEventListeners = () => {
            if (hasEventListeners.value) return;
            document.addEventListener('click', clickOutsideListener, true);
            hasEventListeners.value = true;
        }

        const removeEventListeners = () => {
            if (!hasEventListeners.value) return;
            document.removeEventListener('click', clickOutsideListener, true);
            hasEventListeners.value = false;
        }

        const clear = () => {
            searchInput.value = '';
            results.value = data.value;
            removeEventListeners();
        }

        const closeDropdown = () => {
            isDropdownOpen.value = false;
            clear();
        }

        const focus = async () => {
            searchInputElement.value?.focus();
            if (!props.scrollIntoView) return;
            await nextTick();
            searchInputElement.value?.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }

        const openDropdown = async () => {
            isDropdownOpen.value = true;
            addEventListeners();
            await nextTick();
            await focus();
        }

        const selectItem = async (item) => {
            selectedItem.value = item;
            emit('change', unref(item));
            closeDropdown();

            // When new item is selected trigger change event for select element
            await nextTick();
            const selectElementChangeEvent = new CustomEvent("change", {});
            selectElement.value.dispatchEvent(selectElementChangeEvent);

            selectedItemIndex.value = data.value.findIndex(o => o[props.valueProperty] === selectedItem.value[props.valueProperty])
        }

        /**
         * Move the scroll based on the highlighted item
         */

        const checkScroll = () => {
            if (!dropdownListElement.value) return;
            const hoveredItem = dropdownListElement.value.children?.[selectedItemIndex.value];
            const dropdownListElementRect = dropdownListElement.value?.getBoundingClientRect();
            const hoveredItemElementRect = hoveredItem?.getBoundingClientRect();

            const dropdownScrollTop = dropdownListElement.value.scrollTop;
            const dropdownMenuTop = dropdownListElementRect?.top;
            const hoveredItemElementTop = hoveredItemElementRect?.top;
            const dropdownMenuBottom = dropdownListElementRect?.bottom;
            const hoveredItemElementBottom = hoveredItemElementRect?.bottom;

            if (hoveredItemElementTop < dropdownMenuTop) {
                dropdownListElement.value.scrollTop = hoveredItem.offsetTop + hoveredItemElementRect.height;
            } else if (hoveredItemElementBottom > dropdownMenuBottom) {
                dropdownListElement.value.scrollTop = hoveredItem.offsetTop - (dropdownListElementRect.height - hoveredItemElementRect.height);
            }
        }

        /*************************************************
         * DOM Event Callbacks
         **************************************************/

        const onSearch = () => {
            if (!hasMinSearchLength) return;
            if (isFunction(props.search)) return results.value = props.search(searchQuery.value, data.value);
            return inPlaceSearch()
        }

        const onFocus = () => { if(!props.disabled) openDropdown() };
        const onBlur = () => {};
        const onEnter = () => selectItem(results.value[selectedItemIndex.value]);
        const onMoveUp = () => selectedItemIndex.value = (selectedItemIndex.value + numberOfResults.value - 1) % numberOfResults.value;
        const onMoveDown = () => selectedItemIndex.value = (selectedItemIndex.value + 1) % numberOfResults.value;
        const onKeyDown = (e) => { if(searchDisabled.value) e.preventDefault() };

        // On move check if the item in viewport
        watch(selectedItemIndex, checkScroll);

        /*************************************************
         * CSS Classes
         **************************************************/

        const rootClass = computed(() => ([
            'v-select-search',
            {'open': isDropdownOpen.value},
            {'disabled': props.disabled}
        ]))

        return {
            selectedItemIndex,
            // Refs
            rootElement,
            dropdownListElement,
            searchInputElement,
            inputWrapperElement,
            selectElement,

            // CSS Classes
            rootClass,

            // State
            isLoading,
            isDropdownOpen,
            results,
            searchInput,
            hasSelectedItem,
            selectedItem,
            selectedItemValue,
            selectedItemPlaceholder,
            searchDisabled,
            isInSearchMode,

            // Methods
            isItemSelected,
            closeDropdown,
            selectItem,

            // DOM Events
            onSearch,
            onFocus,
            onBlur,
            onEnter,
            onMoveUp,
            onMoveDown,
            onKeyDown,
        }
    }
}
</script>

<template>
    <div ref="rootElement" :class="rootClass">
        <select
            class="hide-sr"
            :name="name"
            :required="required"
            @focus="onFocus"
            ref="selectElement"
            aria-hidden="true"
            tabindex="-1">
            <option v-if="!hasSelectedItem" selected disabled></option>
            <option
                v-for="(item) in results"
                :key="item[valueProperty]"
                :value="item[valueProperty]"
                :selected="selectedItemValue === item[valueProperty]">{{ item[searchProperty] }}</option>
        </select>

        <div ref="inputWrapperElement" class="v-select-search__wrapper">
            <input
                :id="id"
                class="v-select-search__input v-select-search__display"
                ref="searchInputElement"
                type="text"
                autocomplete="off"
                v-model="searchInput"
                @input="onSearch"
                @keydown.enter.prevent="onEnter"
                @keydown.tab="closeDropdown"
                @keydown.up="onMoveUp"
                @keydown.down="onMoveDown"
                @keydown.esc="closeDropdown"
                @keydown="onKeyDown"
                @focus="onFocus"
                @blur="onBlur"
                :disabled="disabled"
                readonly
            />
            <div class="v-select-search__display" :class="{filtered: isInSearchMode}">
                <slot v-if="hasSelectedItem" name="value" :value="selectedItem">{{ selectedItemPlaceholder }}</slot>
                <span v-else class="v-select-search__placeholder">{{ placeholder }}</span>
            </div>
            <svg v-if="!disabled" class="icon v-select-search__arrow" role="img">
                <use xlink:href="#icon-chevron-small-down"></use>
            </svg>
        </div>

        <div class="v-select-search__menu" v-show="isDropdownOpen">
            <ul ref="dropdownListElement" class="v-select-search__list">
                <li
                    v-if="!results.length"
                    class="v-select-search__item disabled">
                    <slot name="empty-state"></slot>
                </li>
                <li
                    v-for="(item, index) in results"
                    :key="item[valueProperty]"
                    :class="['v-select-search__item', {'selected': isItemSelected(index)}]"
                    @click.prevent="selectItem(item)">
                    <slot name="item" :item="item">{{ item[searchProperty] }}</slot>
                </li>
            </ul>
        </div>
    </div>
</template>
