const SELECTORS = {
	inputWrapper: '.reveal__input--input',
	checkbox: '.reveal__input--checkbox',
};

const CLASSES = {
	active: 'active',
};

export class RevealInput {
	constructor() {
		this.inputWrapper = document.querySelector(SELECTORS.inputWrapper);
		this.checkbox = document.querySelector(SELECTORS.checkbox);

		const requiredElements = [this.inputWrapper, this.checkbox];

		if (requiredElements.some((ele) => !ele)) {
			return;
		}

		this.bindEvents();
		this.setup();
	}

	static init() {
		// eslint-disable-next-line
		new RevealInput();
	}

	/**
	 * Bind `this` explicitly for event listeners.
	 */
	bindEvents() {
		['handleToggleInput'].forEach((e) => {
			this[e] = this[e].bind(this);
		});
	}

	handleToggleInput() {
		if (this.checkbox.checked) {
			this.inputWrapper.classList.add(CLASSES.active);
		} else {
			this.inputWrapper.classList.remove(CLASSES.active);
		}
	}

	setupEventListeners() {
		this.checkbox.addEventListener('change', this.handleToggleInput);
		window.addEventListener('load', this.handleToggleInput);
	}

	setup() {
		this.setupEventListeners();
	}

	destroy() {
		this.checkbox.removeEventListener('change', this.handleToggleInput);
	}
}
