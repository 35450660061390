<template>
	<div :id="id"></div>
</template>

<script>
import postscribe from 'postscribe';

export default {
	name: 'VScript',
	components: {},
	props: {
		url: {
			type: String,
			required: false,
		},
		id: {
			type: String,
			required: false,
			default: 'w-banner',
		},
	},
	data() {
		return {};
	},
	mounted() {
		postscribe(
			'#w-banner',
			'<script id="banner-script" type="text/javascript" src="' + this.url + '""><\/script>',
			{
				done: function () {
					document.querySelector('#banner-script').remove();
				},
			}
		);
		
	},
};
</script>
