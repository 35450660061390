import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { LAPTOP_MEDIA_QUERY } from '../../common/components/utils/constants';

const SELECTORS = {
	// Navigation
	navWrapper: '.our-stories-navigation-wrapper',
	ourStoriesSearch: '.our-stories-search',
	ourStoriesSearchInput: '.our-stories-search-input',
	activateBtn: '.activate',
	// Mobile Menu
	showCategories: '[data-type="show-filters"]',
	hideCategories: '[data-type="hide-filters"]',
	mobileFilters: '.clinic-search__filters-body',
	navigation: '#nav',
	mobileNav: '.clinic-search__header--mobile',
	hideFiltersContainer: '[data-type="hide-filters-container"]',
	mobileHeader: '.our-stories-container-header',
	categoriesMenu: '.categories-menu',
	searchMenu: '.search-menu',
	searchBtnMenu: '.our-stories-search-button-menu',
	// Search page
	searchResultsInput: '.stories-search-result-input',
	searchResultsBtn: '.our-stories-search-results-button',
};

const CLASSES = {
	active: 'active',
	isSticky: 'is-sticky',
	displayNone: 'display--none',
};

const initialOffset = 704;

export class OurStories {
	constructor() {
		this.navWrapper = document.querySelector(SELECTORS.navWrapper);
		this.ourStoriesSearch = document.querySelector(SELECTORS.ourStoriesSearch);
		this.activateBtn = document.querySelector(SELECTORS.activateBtn);
		this.ourStoriesSearchInput = document.querySelector(SELECTORS.ourStoriesSearchInput);
		this.showCategories = document.querySelector(SELECTORS.showCategories);
		this.hideCategories = document.querySelector(SELECTORS.hideCategories);
		this.mobileFilters = document.querySelector(SELECTORS.mobileFilters);
		this.navigation = document.querySelector(SELECTORS.navigation);
		this.mobileNav = document.querySelector(SELECTORS.mobileNav);
		this.hideFiltersContainer = document.querySelector(SELECTORS.hideFiltersContainer);
		this.mobileHeader = document.querySelector(SELECTORS.mobileHeader);
		this.searchResultInput = document.querySelector(SELECTORS.searchResultsInput);
		this.searchResultBtn = document.querySelector(SELECTORS.searchResultsBtn);
		this.searchBtnMenu = document.querySelector(SELECTORS.searchBtnMenu);
		this.categoriesMenu = document.querySelector(SELECTORS.categoriesMenu);
		this.searchMenu = document.querySelector(SELECTORS.searchMenu);

		const requiredElements = [this.navWrapper];

		if (requiredElements.some((el) => !el)) {
			return;
		}

		this.mediaQuery = window.matchMedia(LAPTOP_MEDIA_QUERY);
		this.navWrapper._originalOffsetTop = initialOffset || this.navWrapper.offsetTop;
		this.searchInputValue = '';

		if (this.isMobile()) this.mobileHeader.classList.remove(CLASSES.displayNone);

		this.bindEvents();
		this.setDefaultClass();
		this.setup();
	}

	static init() {
		// eslint-disable-next-line no-unused-vars
		const ourStories = new OurStories();
	}

	setDefaultClass() {
		if (this.navWrapper.offsetTop > initialOffset) this.navWrapper.classList.add(CLASSES.isSticky);
	}

	observeNav() {
		const detect = (elm, cb) => cb & cb(elm.offsetTop !== elm._originalOffsetTop); // eslint-disable-line no-bitwise

		const onSticky = (isSticky) => {
			this.navWrapper.classList.toggle(CLASSES.isSticky, isSticky);
		};

		detect(this.navWrapper, onSticky);
	}

	toggleSearch(isOpen = false) {
		this.ourStoriesSearch.classList.toggle(CLASSES.active, isOpen);
	}

	closeSearchInput(event) {
		if (!this.ourStoriesSearch.contains(event.target)) {
			this.toggleSearch(false);
		}
	}

	/**
	 * Is mobile media query met.
	 * @return {boolean}
	 */
	isMobile() {
		return this.mediaQuery.matches;
	}

	onSearchClick() {
		this.searchMenu.classList.toggle(CLASSES.displayNone);
		this.categoriesMenu.classList.toggle(CLASSES.displayNone, true);
		this.showFilters();
	}

	onCategoriesClick() {
		this.categoriesMenu.classList.toggle(CLASSES.displayNone);
		this.searchMenu.classList.toggle(CLASSES.displayNone, true);
		this.showFilters();
	}

	showFilters() {
		this.hideFiltersContainer.removeEventListener('transitionend', this.helperOnHideFilters);

		disableBodyScroll(this.mobileFilters);
		/**
		 * `timeout` is `null` so that animation can start immediately in the next event cycle(when other elements are rendered)
		 */
		setTimeout(() => {
			// class for button animation
			this.hideCategories.classList.add(CLASSES.active);
		}, null);

		this.mobileNav.classList.remove(CLASSES.displayNone);
		this.hideFiltersContainer.classList.remove(CLASSES.displayNone);
		this.navigation.classList.add(CLASSES.displayNone);
		this.mobileFilters.classList.add(CLASSES.active);
		this.hideCategories.classList.remove(CLASSES.displayNone);
	}

	hideFilters() {
		enableBodyScroll(this.mobileFilters);

		this.hideCategories.classList.remove(CLASSES.active);

		this.hideFiltersContainer.addEventListener('transitionend', this.helperOnHideFilters);
	}

	helperOnHideFilters() {
		this.hideFiltersContainer.classList.add(CLASSES.displayNone);
		this.navigation.classList.remove(CLASSES.displayNone);
		this.mobileFilters.classList.remove(CLASSES.active);
		this.hideCategories.classList.add(CLASSES.displayNone);
		this.mobileNav.classList.add(CLASSES.displayNone);
	}

	bindEvents() {
		[
			'observeNav',
			'setDefaultClass',
			'toggleSearch',
			'closeSearchInput',
			'showFilters',
			'hideFilters',
			'helperOnHideFilters',
			'onMediaQueryChange',
			'clearSearchInput',
			'onSearchInputChange',
			'onCategoriesClick',
			'onSearchClick',
		].forEach((e) => {
			this[e] = this[e].bind(this);
		});
	}

	// Search Page
	clearSearchInput() {
		this.searchInputValue = '';
		this.searchResultInput.value = '';
		this.searchResultBtn.classList.add(CLASSES.displayNone);
	}

	onSearchInputChange(e) {
		this.searchInputValue = e.target.value;
		this.searchResultBtn.classList.toggle(CLASSES.displayNone, !this.searchInputValue.length);
	}
	// End

	/**
	 * When the media query changes to desktop remove all mobile classes.
	 * @param {MediaQueryListEvent} e
	 */
	onMediaQueryChange(e) {
		if (this.isMobile()) this.mobileHeader.classList.remove(CLASSES.displayNone);
		else this.mobileHeader.classList.add(CLASSES.displayNone);

		if (!e.matches) {
			this.destroy();
		} else {
			this.setupEventListeners();
		}
	}

	/**
	 * Setup all event listeners used by the component.
	 */
	setupEventListeners() {
		document.addEventListener('scroll', this.observeNav);
		document.addEventListener('click', this.closeSearchInput);
		// Mobile Menu
		this.activateBtn?.addEventListener('click', this.toggleSearch);
		this.showCategories?.addEventListener('click', this.onCategoriesClick);
		this.hideCategories?.addEventListener('click', this.hideFilters);
		this.searchBtnMenu?.addEventListener('click', this.onSearchClick);
		// Search Page
		this.searchResultInput?.addEventListener('input', this.onSearchInputChange);
		this.searchResultBtn?.addEventListener('click', this.clearSearchInput);
	}

	destroy() {
		// Mobile Menu
		this.showCategories?.removeEventListener('click', this.onCategoriesClick);
		this.hideCategories?.removeEventListener('click', this.hideFilters);
		this.hideFiltersContainer?.removeEventListener('transitionend', this.helperOnHideFilters);
	}

	setup() {
		this.mediaQuery.addEventListener('change', this.onMediaQueryChange);

		this.setupEventListeners();
	}
}
