import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { http } from '../common/http';
import '../common/components/utils/dayjs';

import { Navigation } from './components/Navigation';
import { Dropdown } from '../common/components/Dropdown';
import { Tooltip } from './components/Tooltip';
import { Tabs } from './components/Tabs';
import { Modal } from '../common/Modal';
import { FlashMessage } from './components/FlashMessage';
import { Video } from './components/Video';
import { Accordion } from './components/Accordion';
import { NavigationDropdown } from './components/NavigationDropdown';
import { UploadImage } from './components/UploadImage';
import { RevealInput } from './components/RevealInput';
import { OurStories } from './pages/our-stories';

import VUploadList from './components/VUploadList.vue';
import VUploadItem from './components/VUploadItem.vue';
import VTextEditor from './components/VTextEditor.vue';
import VTimeRange from './components/VTimeRange.vue';
import VScript from './components/VScript.vue';
import VTreatmentDaysAndShifts from '../common/components/booking/VTreatmentDaysAndShifts.vue';
import VSingleTreatment from './components/offline-booking/VSingleTreatment.vue';
import VWeeklyTreatment from './components/offline-booking/VWeeklyTreatment.vue';
import VTreatmentTime from './components/offline-booking/VTreatmentTime.vue';
import VBookingTotal from './components/booking/VBookingTotal.vue';
import VInputStepper from './components/VInputStepper.vue';
import VSelectSearch from './components/VSelectSearch.vue';
import UpdateTimeframe from './pages/UpdateTimeframe.vue';
import VOfflineBooking from './components/offline-booking/VOfflineBooking.vue';

import { DropdownCheckList } from './components/DropdownCheckList';
import { CalendarGroup } from './components/CalendarGroup';
import { SearchFilters } from './components/SearchFilters';
import { SliderGroup } from './components/SliderGroup';

import { onTabsChange } from './pages/home';
import { onNLTabsChange } from './pages/newsletter-library';
import { Lists } from '../common/components/Lists';

import createServer from './mock';
import { scrollIntoView } from './utils/scrollIntoView';

if (Number(process.env.MIX_MOCK_SERVER)) {
	createServer();
}

window.scrollIntoView = scrollIntoView;

// initialize Vue components
const vueWrapper = [
	...document.querySelectorAll(
		'.vue-app-wrapper:not([class*="bookdialysis-search-form"]):not([class*="bookdialysis-search-results"])'
	),
];

window.http = http;

if (vueWrapper.length) {
	const store = createPinia();
	vueWrapper.forEach((appEl) => {
		const app = createApp({})
			.component('v-treatment-days-and-shifts', VTreatmentDaysAndShifts)
			.component('v-treatment-time', VTreatmentTime)
			.component('v-single-treatment', VSingleTreatment)
			.component('v-weekly-treatment', VWeeklyTreatment)
			.component('v-booking-total', VBookingTotal)
			.component('v-upload-list', VUploadList)
			.component('v-upload-item', VUploadItem)
			.component('v-input-stepper', VInputStepper)
			.component('v-select-search', VSelectSearch)
			.component('v-update-timeframe', UpdateTimeframe)
			.component('v-offline-booking', VOfflineBooking)
			.component('v-text-editor', VTextEditor)
			.component('v-time-range', VTimeRange)
			.component('v-script', VScript)
			.use(store);

		// Add window to Vue 3
		app.config.globalProperties.window = window;
		app.mount(appEl);
	});
}

Modal.init();
Tooltip.init();
Dropdown.init();
DropdownCheckList.init();
Navigation.init();
CalendarGroup.init();
SliderGroup.init();
FlashMessage.init();
Video.init();

Accordion.init();
NavigationDropdown.init();
SearchFilters.init();
Tabs.init({ onChange: onTabsChange });
Tabs.init({ onChange: onNLTabsChange });
UploadImage.init();
RevealInput.init();
Lists.init();
OurStories.init();

const page = document.querySelector('[data-page-name]');
const currentPageName = page?.dataset?.pageName;

if (currentPageName === 'autocomplete') {
	import(/* webpackChunkName: "autocomplete" */ './pages/autocomplete').then((module) => {
		const { init } = module;
		init();
	});
}

if (currentPageName === 'booking') {
	import(/* webpackChunkName: "booking" */ './components/booking/Booking').then((module) => {
		const { Booking } = module;
		Booking.init();
	});
}

if (currentPageName === 'home') {
	import(/* webpackChunkName: "home" */ './pages/home').then((module) => {
		const { init } = module;
		init();
	});
}

if (currentPageName === 'book-treatment') {
	import(/* webpackChunkName: "book-treatment" */ './pages/book-treatment').then((module) => {
		const { init } = module;
		init();
	});
}

if (currentPageName === 'newsletter-library') {
	import(/* webpackChunkName: "newsletter-library" */ './pages/newsletter-library').then(
		(module) => {
			const { init } = module;
			init();
		}
	);
}
