<script>
import { useOfflineBookingStore } from '../../../common/components/offline-booking/store';
import VTreatmentTime from './VTreatmentTime.vue';
import {useTreatmentCalendar} from './useTreatmentCalendar';
import {computed} from "vue";
import dayjs from "dayjs";
import {BE_DAYS_OF_WEEK} from "../../../common/components/utils/constants";

export default {
    name: "VSingleTreatment",
    components: {VTreatmentTime},
    props: {
        shifts: {
            type: Object,
            required: false,
        },
        shiftSelectionPlaceholder: {
			type: String,
			required: false,
		},
    },
    setup(props) {
        const store = useOfflineBookingStore();

        useTreatmentCalendar();

        function onDateChange(e) {
            let { from } = e.detail;
            from = from?.getTime();
            store.treatmentDate = { to: from, from };
        }

        const selectedShift = computed(() => {
            const dayOfWeek = dayjs(store.treatmentDate?.from ?? '').get('d') ?? -1;
            const day = BE_DAYS_OF_WEEK?.[dayOfWeek];
            return props.shifts?.[day] || [];
        });

        return {
            store,
            onDateChange,
            selectedShift,
            getError: store.getError
        }
    }
}
</script>

<template>
    <div>
        <div class="mb-32">
            <slot name="single-treatment-date" :onDateChange="onDateChange"></slot>
            <div class="input-error" v-if="getError('from')">{{ getError('from') }}</div>
            <div class="input-error" v-if="getError('to')">{{ getError('to') }}</div>
            <div class="input-error" v-if="getError('date')">{{ getError('date') }}</div>
        </div>
        <v-treatment-time
            v-if="selectedShift.length"
            :shift-error="getError('shift')"
            :start-error="getError('start')"
            :end-error="getError('end')"
            :selected-shift="store.treatment.shift"
            :shifts="selectedShift"
            :placeholder="shiftSelectionPlaceholder"
            v-model="store.treatment">
            <template v-for="(_, slot) of $slots" v-slot:[slot]="scope">
                <slot :name="slot" v-bind="scope"></slot>
            </template>
        </v-treatment-time>
    </div>
</template>

