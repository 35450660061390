const SELECTORS = {
	videoOverlay: '.video__overlay',
	video: '.video video',
};

const CLASSES = {
	hide: 'hide',
};

export class Video {
	constructor() {
		this.videoOverlay = document.querySelector(SELECTORS.videoOverlay);
		this.video = document.querySelector(SELECTORS.video);

		const requiredElements = [this.videoOverlay, this.video];

		if (requiredElements.some((el) => !el)) {
			return;
		}

		this.bindEvents();
		this.setup();
	}

	static init() {
		// eslint-disable-next-line
		new Video();
	}

	/**
	 * Bind `this` explicitly for event listeners.
	 */
	bindEvents() {
		['handlePlayVideo', 'handleOverlayFadeIn'].forEach((e) => {
			this[e] = this[e].bind(this);
		});
	}

	handlePlayVideo() {
		this.video.play();
		this.overlayFadeOut();
	}

	handleOverlayFadeIn() {
		this.videoOverlay.classList.remove(CLASSES.hide);
	}

	overlayFadeOut() {
		this.videoOverlay.classList.add(CLASSES.hide);
	}

	/**
	 * Setup all event listeners used by the component.
	 */
	setupEventListeners() {
		this.videoOverlay.addEventListener('click', this.handlePlayVideo);
		this.video.addEventListener('ended', this.handleOverlayFadeIn);
	}

	setup() {
		this.setupEventListeners();
	}
}
