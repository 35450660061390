import '../common/_icons';

// define map variable before initialization
window.MAP_STYLE = [
	{
		elementType: 'geometry',
		stylers: [
			{
				color: '#ebe3cd',
			},
		],
	},
	{
		elementType: 'labels.text.fill',
		stylers: [
			{
				color: '#523735',
			},
		],
	},
	{
		elementType: 'labels.text.stroke',
		stylers: [
			{
				color: '#f5f1e6',
			},
		],
	},
	{
		featureType: 'administrative',
		elementType: 'geometry.stroke',
		stylers: [
			{
				color: '#e86825',
			},
		],
	},
	{
		featureType: 'administrative.land_parcel',
		elementType: 'geometry.stroke',
		stylers: [
			{
				color: '#dcd2be',
			},
		],
	},
	{
		featureType: 'administrative.land_parcel',
		elementType: 'labels.text.fill',
		stylers: [
			{
				color: '#ae9e90',
			},
		],
	},
	{
		featureType: 'landscape.natural',
		elementType: 'geometry',
		stylers: [
			{
				color: '#dfd2ae',
			},
		],
	},
	{
		featureType: 'poi',
		elementType: 'geometry',
		stylers: [
			{
				color: '#dfd2ae',
			},
		],
	},
	{
		featureType: 'poi',
		elementType: 'labels.text.fill',
		stylers: [
			{
				color: '#93817c',
			},
		],
	},
	{
		featureType: 'poi.park',
		elementType: 'geometry.fill',
		stylers: [
			{
				color: '#c6b89f',
			},
		],
	},
	{
		featureType: 'poi.park',
		elementType: 'labels.text.fill',
		stylers: [
			{
				color: '#447530',
			},
		],
	},
	{
		featureType: 'road',
		elementType: 'geometry',
		stylers: [
			{
				color: '#f5f1e6',
			},
		],
	},
	{
		featureType: 'road',
		elementType: 'labels',
		stylers: [
			{
				visibility: 'off',
			},
		],
	},
	{
		featureType: 'road.arterial',
		elementType: 'geometry',
		stylers: [
			{
				color: '#fdfcf8',
			},
		],
	},
	{
		featureType: 'road.highway',
		elementType: 'geometry',
		stylers: [
			{
				color: '#f8c967',
			},
		],
	},
	{
		featureType: 'road.highway',
		elementType: 'geometry.stroke',
		stylers: [
			{
				color: '#e9bc62',
			},
		],
	},
	{
		featureType: 'road.highway.controlled_access',
		elementType: 'geometry',
		stylers: [
			{
				color: '#f8c967',
			},
		],
	},
	{
		featureType: 'road.highway.controlled_access',
		elementType: 'geometry.stroke',
		stylers: [
			{
				color: '#e9bc62',
			},
		],
	},
	{
		featureType: 'road.local',
		elementType: 'labels.text.fill',
		stylers: [
			{
				color: '#806b63',
			},
		],
	},
	{
		featureType: 'transit.line',
		elementType: 'geometry',
		stylers: [
			{
				color: '#dfd2ae',
			},
		],
	},
	{
		featureType: 'transit.line',
		elementType: 'labels.text.fill',
		stylers: [
			{
				color: '#8f7d77',
			},
		],
	},
	{
		featureType: 'transit.line',
		elementType: 'labels.text.stroke',
		stylers: [
			{
				color: '#ebe3cd',
			},
		],
	},
	{
		featureType: 'transit.station',
		elementType: 'geometry',
		stylers: [
			{
				color: '#dfd2ae',
			},
		],
	},
	{
		featureType: 'water',
		elementType: 'geometry.fill',
		stylers: [
			{
				color: '#98beae',
			},
		],
	},
	{
		featureType: 'water',
		elementType: 'labels.text.fill',
		stylers: [
			{
				color: '#92998d',
			},
		],
	},
];

// import bacics from
// eslint-disable-next-line import/first
import '.';
