<script>
import { computed, ref, watch, watchEffect } from 'vue';
import VSelectSearch from '../VSelectSearch';
import { randomString, isObject, parseToFromTime, parseShiftMinMax } from '../../../common/components/utils/utils';
import { useVModel } from '../../utils/use';

export default {
	components: { VSelectSearch },
	props: {
		modelValue: Object,
		shifts: {
			type: Array,
			required: true,
		},
		selectedShift: {
			type: [Object, String, Number],
			required: false,
		},
		shiftName: {
			type: String,
			required: false,
			default: 'shift',
		},
		startName: {
			type: String,
			required: false,
			default: 'start',
		},
		endName: {
			type: String,
			required: false,
			default: 'end',
		},
		shiftError: {
			type: [String, Boolean],
			required: false,
			default: false,
		},
		startError: {
			type: [String, Boolean],
			required: false,
			default: false,
		},
		endError: {
			type: [String, Boolean],
			required: false,
			default: false,
		},
		shiftDisabled: {
			type: Boolean,
			required: false,
			default: false,
		},
		shiftSelectionPlaceholder: {
			type: String,
			required: false,
		},
	},
	emits: ['update:modelValue'],
	setup(props, { emit }) {
		const id = randomString();
		let data;
		// V-Model
		if (props.modelValue) {
			data = useVModel('modelValue', props, emit);
		} else {
			const shift = isObject(props.selectedShift)
				? props.selectedShift
				: props.shifts.find((s) => s.id === props.selectedShift);
			data = ref({
				shift,
			});
		}

		/*************************************************
		 * Shift
		 **************************************************/
		const isShiftSelect = computed(() => !!data.value?.shift);
		const shiftMinMax = computed(() => parseShiftMinMax(data.value?.shift?.shift));

		const changeShift = (shift) => {
			if (data.value) {
				data.value.shift = shift;
				data.value.start = null;
				data.value.end = null;
			}
		};

		/*************************************************
		 * Treatment Start
		 **************************************************/
		const toFrom = computed(() => parseToFromTime(data.value?.start, data.value?.end));

		const treatmentStartInput = ref(null);
		const treatmentStartDate = computed(() => toFrom.value.from);
		// If the input is valid based on min/max attributes on the number input.
		const treatmentStartInputInRange = ref(true);
		// Below the start
		const isTreatmentStartDateUnderflow = computed(
			() => treatmentStartDate.value?.getTime() < shiftMinMax.value.min?.getTime()
		);
		const isTreatmentStartDateOverflow = computed(
			() => treatmentStartDate.value?.getTime() > shiftMinMax.value.max?.getTime()
		);
		watch(treatmentStartDate, () => {
			treatmentStartInputInRange.value = treatmentStartInput.value?.validity?.valid;
		});

		const treatmentStartBeforeEnd = computed(() => {
			let beforeEnd = true;
			if (treatmentStartDate.value && treatmentEndDate.value) {
				beforeEnd = treatmentStartDate.value?.getTime() < treatmentEndDate.value?.getTime();
			}
			return beforeEnd;
		});

		/*************************************************
		 * Treatment End
		 **************************************************/
		const treatmentStartValid = computed(
			() => treatmentStartInputInRange.value && treatmentStartBeforeEnd.value
		);

		const treatmentEndInput = ref(null);
		const treatmentEndDate = computed(() => toFrom.value.to);
		// If the input is valid based on min/max attributes on the number input.
		const treatmentEndInputInRange = ref(true);
		const isTreatmentEndDateUnderflow = computed(
			() => treatmentEndDate.value?.getTime() < shiftMinMax.value.min?.getTime()
		);
		const isTreatmentEndDateOverflow = computed(
			() => treatmentEndDate.value?.getTime() > shiftMinMax.value.max?.getTime()
		);
		watch(treatmentEndDate, () => {
			treatmentEndInputInRange.value = treatmentEndInput.value?.validity?.valid;
		});

		const treatmentEndAfterStart = computed(() => {
			let afterStart = true;
			if (treatmentStartDate.value && treatmentEndDate.value) {
				afterStart =
					treatmentEndDate.value?.getTime() > treatmentStartDate.value?.getTime();
			}
			return afterStart;
		});

		const treatmentEndValid = computed(
			() => treatmentEndInputInRange.value && treatmentEndAfterStart.value
		);

		/*************************************************
		 * Form validity
		 **************************************************/
		const requiredFields = computed(() => [
			isShiftSelect.value,
			treatmentStartDate.value,
			treatmentEndDate.value,
			treatmentStartValid.value,
			treatmentEndValid.value,
		]);

		watchEffect(() => {
			if (data.value) data.value.valid = requiredFields.value.every(Boolean);
		});

		return {
			id,
			isShiftSelect,
			treatmentStartInput,
			treatmentStartValid,
			treatmentEndValid,
			treatmentStartDate,
			treatmentEndInput,
			treatmentEndDate,
			treatmentStartInputInRange,
			treatmentEndInputInRange,
			treatmentStartBeforeEnd,
			treatmentEndAfterStart,

			// Overflow & Underflow errors
			isTreatmentStartDateUnderflow,
			isTreatmentStartDateOverflow,
			isTreatmentEndDateUnderflow,
			isTreatmentEndDateOverflow,
			shiftMinMax,

			// Methods
			changeShift,

			// v-model
			data,
			toFrom,
		};
	},
};
</script>

<template>
	<div>
		<div class="mb-16">
			<div class="heading-4 mb-16"><slot name="title"></slot></div>
			<label class="input-label" :for="`${id}-shift`">
				<slot name="treatment-shift-label"></slot>
			</label>
			<v-select-search
				required
				:search="false"
				:id="`${id}-shift`"
				:name="shiftName"
				:placeholder="shiftSelectionPlaceholder"
				@change="changeShift"
				:selected="selectedShift"
				:data="shifts"
				:disabled="shiftDisabled"
			>
				<template #value="{ value }">
					<div class="flex-center flex--spread mr-12">
						<span>{{ value.name }}</span>
						<span v-if="!shiftDisabled">{{ value.shift }}</span>
					</div>
				</template>
				<template #item="{ item }">
					<div class="flex-center flex--spread">
						<span>{{ item.name }}</span>
						<span>{{ item.shift }}</span>
					</div>
				</template>
			</v-select-search>
			<div v-if="shiftError" class="input-error">{{ shiftError }}</div>
		</div>
		<div class="group-input--inline">
			<div class="flex-1 mb-16 mb-laptop-0">
				<label :for="`${id}-start`" class="input-label">
					<slot name="treatment-start-label"></slot>
				</label>
				<div class="input-with-icon" :class="{ disabled: !isShiftSelect }">
					<!-- For time validation add these properties to input field:
						:min="shiftMinMax.minString"
						:max="shiftMinMax.maxString"
					-->
					<input
						ref="treatmentStartInput"
						type="time"
						:name="startName"
						:id="`${id}-start`"
						class="input input--primary"
						:class="{ error: !treatmentStartValid }"
						:disabled="!isShiftSelect"
						v-model="data.start"
					/>

					<svg class="icon input-with-icon__suffix" role="img">
						<use xlink:href="#icon-clock"></use>
					</svg>
				</div>

				<!-- For time validation uncomment this part 

				<div v-if="!treatmentStartBeforeEnd" class="input-error">
					<slot name="treatment-start-range-error"></slot>
				</div>
				<div v-if="isTreatmentStartDateUnderflow" class="input-error">
					<slot
						name="treatment-underflow-error"
						:min="shiftMinMax.minString"
						:max="shiftMinMax.maxString"
					></slot>
				</div>
				<div v-if="isTreatmentStartDateOverflow" class="input-error">
					<slot
						name="treatment-overflow-error"
						:min="shiftMinMax.minString"
						:max="shiftMinMax.maxString"
					></slot>
				</div>

				<div v-if="startError" class="input-error">{{ startError }}</div>
				-->
			</div>
			<div class="flex-1">
				<label :for="`${id}-end`" class="input-label">
					<slot name="treatment-end-label"></slot>
				</label>
				<div class="input-with-icon" :class="{ disabled: !isShiftSelect }">
					<!-- For time validation add these properties to input field:
						:min="shiftMinMax.minString"
						:max="shiftMinMax.maxString"
					-->
					<input
						ref="treatmentEndInput"
						type="time"
						:name="endName"
						:id="`${id}-end`"
						class="input input--primary"
						:class="{ error: !treatmentEndValid }"
						:disabled="!isShiftSelect"
						v-model="data.end"
					/>

					<svg class="icon input-with-icon__suffix" role="img">
						<use xlink:href="#icon-clock"></use>
					</svg>
				</div>

				<!-- For time validation uncomment this part 

				<div v-if="!treatmentEndAfterStart" class="input-error">
					<slot name="treatment-end-range-error"></slot>
				</div>
				<div v-if="isTreatmentEndDateUnderflow" class="input-error">
					<slot
						name="treatment-underflow-error"
						:min="shiftMinMax.minString"
						:max="shiftMinMax.maxString"
					></slot>
				</div>
				<div v-if="isTreatmentEndDateOverflow" class="input-error">
					<slot
						name="treatment-overflow-error"
						:min="shiftMinMax.minString"
						:max="shiftMinMax.maxString"
					></slot>
				</div>

				<div v-if="endError" class="input-error">{{ endError }}</div>
				-->
			</div>
		</div>
	</div>
</template>
