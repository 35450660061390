const SELECTORS = {
	activeTab: '[data-tab].active',
	tabTriggerSelector: '[data-tab]',
};

const CLASSES = {
	active: 'active',
};

export class Tab {
	constructor(parent, config) {
		this.parent = parent;

		const requiredElements = [this.parent];
		if (requiredElements.some((ele) => !ele)) {
			return;
		}

		this.tabId = parent.dataset.tabs;
		this.activeTab = parent.querySelector(SELECTORS.activeTab);
		this.config = config;

		this.bindEvents();
		this.setup();
	}

	/**
	 * Bind `this` explicitly for event listeners.
	 */
	bindEvents() {
		['onClick'].forEach((e) => {
			this[e] = this[e].bind(this);
		});
	}

	hide() {
		// Trigger
		this.activeTab?.classList?.remove(CLASSES.active);
		// Pane
		this.activeDescription = Tab._getTabPane(this.activeTab);
		this.activeDescription?.classList?.remove(CLASSES.active);
		this.activeDescription.hidden = true;
	}

	show() {
		// Trigger
		this.activeTab.classList.add(CLASSES.active);
		// Pane
		this.activeDescription = Tab._getTabPane(this.activeTab);
		this.activeDescription.classList.add(CLASSES.active);
		this.activeDescription.hidden = false;
		// Callback
		this.config.onChange(this.tabId, this.activeTab);
	}

	onClick(event) {
		event.preventDefault();
		const buttonClicked = event.target.closest(SELECTORS.tabTriggerSelector);
		if (buttonClicked && buttonClicked !== this.activeTab) {
			this.hide();
			this.activeTab = buttonClicked;
			this.show();
		}
	}

	setupEventListeners() {
		this.parent.addEventListener('click', this.onClick);
	}

	setup() {
		this.setupEventListeners();
	}

	destroy() {
		this.parent.removeEventListener('click', this.onClick);
	}

	/**
	 * Get the connected tab pane based on the passed tab element.
	 * @param {HTMLElement|null} tab
	 * @return {HTMLElement}
	 * @private
	 */
	static _getTabPane(tab) {
		if (!tab) return null;
		const tabTargetId = tab.dataset.tab;
		return document.getElementById(tabTargetId);
	}
}
