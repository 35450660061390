import dayjs from 'dayjs';
import { nextTick, onMounted, ref } from 'vue';
import { useOfflineBookingStore } from '../../../common/components/offline-booking/store';
import { CalendarCustom } from '../../../common/components/CalendarCustom';
import { dateFormatForAPI } from '../../../common/components/utils/dateFormatForAPI';
import { DATE_FORMAT_API } from '../../../common/components/utils/constants';
import { dateGetMonthRange } from '../../../common/components/utils/dateGetMonthRange';

export function useTreatmentCalendar(endpoint, callback) {
	const store = useOfflineBookingStore();
	const disabledDates = ref([]);
	let calendar = null;
	let calendarElement = null;

	async function updateDisabledDates({ instance }) {
		const [date1] = instance.calendars;
		const date2 = dayjs(date1.dateInstance).add(1, 'month');

		const { from } = dateGetMonthRange({
			date: date1.dateInstance,
			format: DATE_FORMAT_API,
		});

		const { to } = dateGetMonthRange({
			date: date2,
			format: DATE_FORMAT_API,
		});

		const {
			data: { data },
		} = await http.get(window.clinicAvailabilityURL, {
			params: {
				from,
				to,
			},
		});

		const disableDates = data.slots
			.filter((date) => Object.values(date?.overall ?? {}).every((v) => !v))
			.map((status) => status.date);

		instance.setLockDays(disableDates);
	}

	function setPlaceholder(isSingleMode) {
		const { placeholderSingle, placeholderRange } = calendarElement.dataset;
		const placeholder = isSingleMode ? placeholderSingle : placeholderRange;
		if (placeholder) calendarElement.placeholder = placeholder;
	}

	onMounted(async () => {
		await nextTick();
		calendarElement = document.querySelector('[data-rangepicker-treatments]');
		calendar = new CalendarCustom(calendarElement, {
			setup: (instance) => {
				instance.on('before:show', async () => {
					await updateDisabledDates({ instance });
				});
				instance.on('change:month', async () => {
					await updateDisabledDates({ instance });
				});
				instance.on('selected', (...dates) => {
					CalendarCustom.emitEventSelected({ instance, dates });
				});
			},
		});
		setPlaceholder(calendar?.instance.options.singleMode);
	});

	// this function is used for weekly template to update disabled week days
	async function updateWeekDays() {
		const params = {};
		Object.keys(store.treatmentDate).forEach(
			// eslint-disable-next-line no-return-assign
			(key) => (params[key] = dateFormatForAPI(store.treatmentDate[key]))
		);

		const {
			data: { data },
		} = await http.get(endpoint, {
			params,
		});

		callback(data, disabledDates);
	}

	function onSingleModeChange(isSingleMode) {
		const currentIsSingleMode = calendar.instance.options.singleMode;
		let startDate = currentIsSingleMode
			? calendar.instance.getDate()
			: calendar.instance.getStartDate();
		startDate = startDate?.dateInstance;

		calendar.setMode({ single: isSingleMode });
		setPlaceholder(isSingleMode);

		if (!startDate) {
			return;
		}

		calendar.instance.clearSelection();
		if (isSingleMode) {
			calendar.instance.setDate(startDate);
		} else {
			calendar.instance.setDateRange(startDate, startDate);
		}
	}

	return {
		updateWeekDays,
		onSingleModeChange,
	};
}
