import { HEADER_CSS_VARIABLE } from '../components/Navigation';

/**
 * Given an element or a selector to an element scroll to that element in the viewport and offset the navigation.
 * @param {string|HTMLElement} element
 */
export function scrollIntoView(element) {
	let el = element;

	if (typeof el === 'string') el = document.querySelector(element);
	if (!el) return;

	const navigationHeight =
		parseFloat(getComputedStyle(document.documentElement).getPropertyValue(HEADER_CSS_VARIABLE)) ||
		0;

	// On mobile we have booking sticky clinic details
	const bookingClinicDetails = document.querySelector('.booking-clinic-details--float');
	let bookingClinicDetailsHeight;
	if (bookingClinicDetails) {
		bookingClinicDetailsHeight = bookingClinicDetails.offsetHeight;
	}

	const windowWidth = window.matchMedia('(max-width: 1024px)');
	let top = el.offsetTop - navigationHeight;
	if (windowWidth.matches && bookingClinicDetailsHeight) {
		top -= bookingClinicDetailsHeight;
	}

	window.scroll({
		behavior: 'smooth',
		left: 0,
		top,
	});
}
