<script>
const ComponentEvent = {
	deleteItem: 'deleteItem',
	downloadItem: 'downloadItem'
};

/**
 *
 * @version 1.0.0
 * @since
 */
export default {
	name: 'VUploadItem',
	components: {},
	props: {
		name: {
			required: true,
			type: String,
		},
		infoList: {
			required: false,
			type: Array,
			default: () => [],
		},
		deleteUrl: {
			required: false,
			type: String,
			default: () => '',
		},
		enableLocalDelete: {
			required: false,
			type: Boolean,
			default: () => false,
		},
		error: {
			required: false,
			type: String,
			default: () => '',
		},
		isLoading: {
			required: false,
			type: Boolean,
			default: () => false,
		},
	},
	setup() {
		return {
			ComponentEvent,
		};
	},
};
</script>

<template>
	<div :class="['w-upload__documents button--tertiary']">
		<button @click="$emit(ComponentEvent.downloadItem)" :class="['upload__document--download', { 'upload__document--download--loading': isLoading }]">
			<svg class="icon" role="img">
				<use xlink:href="#icon-downloads"></use>
			</svg>
		</button>
		<div class="w-upload__document-info">
			<p class="upload__document-info upload__document-info--name">
				{{ name }}
			</p>
			<p v-if="infoList.length" class="upload__document-info separator--dot">
				<span v-for="info in infoList">
					{{ info }}
				</span>
			</p>
			<p v-if="error" class="upload__document-info error">
				{{ error }}
			</p>
		</div>
		<button
			v-if="deleteUrl || enableLocalDelete"
			class="upload__document--remove"
            type="button"
			@click="$emit(ComponentEvent.deleteItem)"
		>
			<svg class="icon" role="img">
				<use xlink:href="#icon-close"></use>
			</svg>
		</button>
	</div>
</template>
