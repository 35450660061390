const SELECTORS = {
	activeMessage: '[data-flash-message].active',
	authButton: '.auth__flash-message-button',
};

const CLASSES = {
	active: 'active',
};

export class FlashMessage {
	constructor() {
		this.messageActive = document.querySelector(SELECTORS.activeMessage);
		this.authButton = document.querySelector(SELECTORS.authButton);

		const requiredElements = [this.messageActive, this.authButton];
		if (requiredElements.some((ele) => !ele)) {
			// eslint-disable-next-line no-constructor-return
			return;
		}

		this.bindEvents();
		this.setup();
	}

	static init() {
		// eslint-disable-next-line
		new FlashMessage();
	}

	/**
	 * Bind `this` explicitly for event listeners.
	 */
	bindEvents() {
		['setMessageInactive'].forEach((e) => {
			this[e] = this[e].bind(this);
		});
	}

	/**
	 * Is mobile media query met.
	 * @return {boolean}
	 */
	isMobile() {
		return this.mediaQuery.matches;
	}

	/**
	 * When the media query changes to desktop remove all mobile classes.
	 * @param {MediaQueryListEvent} e
	 */
	onMediaQueryChange(e) {
		if (!e.matches) {
			this.destroy();
		} else {
			this.setupEventListeners();
		}
	}

	setMessageInactive() {
		this.messageActive.classList.remove(CLASSES.active);
	}

	setupEventListeners() {
		this.authButton.addEventListener('click', this.setMessageInactive);
	}

	setup() {
		this.setupEventListeners();
		const delay = 10000;
		setTimeout(this.setMessageInactive, delay);
	}
}
