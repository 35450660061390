const SELECTORS = {
	mainWrapper: '.upload__file-section',
	imageInput: '.upload__file-input',
	imageInputLabel: '.upload__file-create',
	imagePreview: '.upload__file-preview',
	imageEdit: '.upload__file-edit',
	imageDelete: '.upload__file-delete',
	imageDeleted: '.upload__file-deleted',
	submitButton: '.upload-submit-button',
};

const CLASSES = {
	active: 'upload__file-uploaded',
	disabled: 'disabled',
};

export class UploadImage {
	constructor() {
		this.mainWrapper = document.querySelector(SELECTORS.mainWrapper);
		this.imageInput = document.querySelector(SELECTORS.imageInput);
		this.imageInputLabel = document.querySelector(SELECTORS.imageInputLabel);
		this.imagePreview = document.querySelector(SELECTORS.imagePreview);
		this.imageEdit = document.querySelector(SELECTORS.imageEdit);
		this.imageDelete = document.querySelector(SELECTORS.imageDelete);
		this.imageDeleted = document.querySelector(SELECTORS.imageDeleted);
		this.submitButton = document.querySelector(SELECTORS.submitButton);

		const requiredElements = [this.imagePreview, this.imageInput, this.imageInputLabel];

		if (requiredElements.some((ele) => !ele)) {
			return;
		}

		this.bindEvents();
		this.setup();
	}

	static init() {
		// eslint-disable-next-line
		new UploadImage();
	}

	/**
	 * Bind `this` explicitly for event listeners.
	 */
	bindEvents() {
		['handleDeletePhoto', 'handleInputChange'].forEach((e) => {
			this[e] = this[e].bind(this);
		});
	}

	// preview photo
	previewPhoto(file) {
		if (!file) return;
		const reader = new FileReader();
		reader.readAsDataURL(file);

		reader.addEventListener('load', () => {
			const image = reader.result;
			this.mainWrapper.classList.add(CLASSES.active);
			if (this.submitButton) this.submitButton.classList.remove(CLASSES.disabled);
			this.imagePreview.style.backgroundImage = `url(${image})`;
		});

		this.imageDeleted.value = '0';
	}

	handleDeletePhoto() {
		this.imagePreview.style.backgroundImage = "url('')";
		this.imageInput.value = null;
		this.imageDeleted.value = '1';
		this.mainWrapper.classList.remove(CLASSES.active);
		if (this.submitButton) this.submitButton.classList.add(CLASSES.disabled);
	}

	handleInputChange(e) {
		const file = e.target.files[0];
		this.previewPhoto(file);
	}

	// prevent the default behaviour browsers might have such as immediately opening links and images
	static preventDefault(e) {
		e.preventDefault();
		e.stopPropagation();
	}

	setupEventListeners() {
		this.imageInput.addEventListener('change', this.handleInputChange);
		this.imageDelete.addEventListener('click', this.handleDeletePhoto);
	}

	setup() {
		this.setupEventListeners();
	}

	destroy() {
		this.imageInput.removeEventListener('change', this.handleUploadPhoto(false));
		this.imageDelete.removeEventListener('click', this.handleDeletePhoto);
	}
}
