import Glide, { Breakpoints, Controls, Images, Swipe } from '@glidejs/glide/dist/glide.modular.esm';
import { parseTemplateOptions } from '../../common/components/utils/pareTemplateOptions';
import { getDir } from '../../common/components/utils/utils';

const SLIDER_OPTION = 'sliderOption';

/**
 *  Slider adds functionality of Glide.js and allows you to add Glide.js options in template.
 *
 *  Glide options: @see {@link https://glidejs.com/docs/options/}
 *
 *  To use Glide options in `template`(html)
 *  use attribute: `data-slider-option-<glide-option>`
 *  pass value: '<value>' or '{ "value": <value> }'
 *
 *  e.g.
 *  How to set options `perView`, `gap` & `breakpoints`
 *
 *	data-slider-option-per-view='3'
 *  data-slider-option-gap='24'
 *  data-slider-option-breakpoints='{
 *                         "600":{
 *                             "perView": 2,
 *                             "gap": 24
 *                         }
 *                     }'
 */
export class Slider {
	constructor(sliderEle, options = {}) {
		const requiredElements = [sliderEle];

		if (requiredElements.some((ele) => !ele)) {
			throw new Error('Slider not initialized');
		}

		const { dataset } = sliderEle;

		const parseOptions = parseTemplateOptions({
			dataset,
			optionPrefix: SLIDER_OPTION,
		});

		/**
		 * Initialize a Slider with custom config options:
		 * - options: @see {@link https://glidejs.com/docs/options/}
		 *
		 * @type {Glide}
		 */
		this.slider = new Glide(sliderEle, {
			direction: getDir(),
			rewind: false,
			...options,
			...parseOptions,
		}).mount({ Controls, Breakpoints, Swipe, Images });
	}
}
