/* eslint-disable */
import { Server } from 'miragejs';

export default function ({ environment = 'development' } = {}) {
	return new Server({
		environment,

		routes() {
			this.post('/api/booking/clinic/1/price', (_, request) => ({
				total_price: `$${Object.keys(JSON.parse(request.requestBody)).length * 320}`
			}))

			this.passthrough()
		},
	});
}
