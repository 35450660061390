<template>
    <div ref="root">
    <div :class="['input-stepper', {'error': hasError}]">
        <button @click="decrement" type="button" class="input-stepper__button" :disabled="!canDecrement">
            <svg class="icon" role="img">
                <use xlink:href="#icon-minus"></use>
            </svg>
        </button>
        <input type="number" :name="name" class="input-stepper__input" :min="localMin" :max="localMax" :disabled="disabled" v-model="refValue" @change.stop />
        <button @click="increment" type="button" class="input-stepper__button" :disabled="!canIncrement">
            <svg class="icon" role="img">
                <use xlink:href="#icon-plus"></use>
            </svg>
        </button>
    </div>
    <div v-if="hasErrorMessage" class="input-error">{{ errorMessage }}</div>
    </div>
</template>

<script>
import {ref, computed, onMounted, onBeforeUnmount, watch} from 'vue';

export default {
    props: {
        name: {
            type: [String],
            required: true
        },
        min: {
            type: [String, Number],
            default: null,
            required: false
        },
        max: {
            type: [String, Number],
            default: null,
            required: false
        },
        disabled: {
            type: [String, Boolean],
            default: false,
            required: false,
        },
        errorMessage: {
            type: String,
            required: false
        },
        value: {
            type: [String, Number],
            required: false
        },
    },
    setup(props, { emit }) {
        const refValue = ref(Number(props.value));
        const root = ref(null)
        const localMin = ref(Number(props.min));
        const localMax = ref(Number(props.max));

        watch(() => props.value, (value) => refValue.value = Number(value));
        watch(() => props.min, (value) => localMin.value = Number(value));
        watch(() => props.max, (value) => localMax.value = Number(value));
        watch(refValue, (value) => emit('change', value))

        /**
         * If the initial value is not set (NaN) set it to min or 0.
         */
        function validateNumber() {
            if (!isFinite(refValue.value)) {
                if (hasMax.value) refValue.value = localMin.value;
                else refValue.value = 0;
            }
        }

        const hasMax = computed(() => localMax.value !== null)
        const hasMin = computed(() => localMin.value !== null)

        const isDisabled = computed(() => props.disabled === '' || !!props.disabled)

        const isMaxLimit = computed(() => hasMax.value ? refValue.value >= localMax.value : false)
        const isMinLimit = computed(() => hasMin.value ? refValue.value <= localMin.value : false)

        const canIncrement = computed(() => !isDisabled.value && !isMaxLimit.value)
        const canDecrement = computed(() => !isDisabled.value && !isMinLimit.value)

        const hasError = computed(() => {
            let minOverflow = false;
            let maxOverflow = false;

            if (hasMax.value && refValue.value > localMax.value) maxOverflow = true;
            if (hasMin.value && refValue.value < localMin.value) minOverflow = true;

            return minOverflow || maxOverflow;
        })

        const hasErrorMessage = computed(() => hasError.value && props.errorMessage && !isDisabled.value)

        function increment() {
            validateNumber();
            if (!isMaxLimit.value) refValue.value++;
            if (isMinLimit.value) refValue.value = localMin.value;
        }

        function decrement() {
            validateNumber();
            if (!isMinLimit.value) refValue.value--;
            if (isMaxLimit.value) refValue.value = localMax.value
        }

        /**
         * Because we are mixin Vue and native there is a need for them to interact.
         * When we set the attributes to an already mounted component Vue won't React.
         * For that reason, we manually watch for attribute change and set the required value.
         *
         * Essentially this simulates prop reactivity.
         *
         * @param mutationsList
         */
        function attributeChange(mutationsList) {
            for(const mutation of mutationsList) {
                if (mutation.type !== 'attributes') continue

                let attr = mutation.attributeName;
                let value = root.value.getAttribute(attr);

                switch (attr) {
                    case 'min': localMin.value = Number(value); break;
                    case 'max': localMax.value = Number(value); break;
                    case 'value': refValue.value = Number(value); break;
                }
            }
        }

        let observer = new MutationObserver(attributeChange)

        onMounted(() => observer.observe(root.value, { attributes: true }))
        onBeforeUnmount(() => observer.disconnect())

        return {
            increment,
            decrement,
            canIncrement,
            canDecrement,
            hasError,
            hasErrorMessage,
            refValue,
            root,
            localMin,
            localMax,
        }
    }
}
</script>
