<script>
import { useOfflineBookingStore } from '../../../common/components/offline-booking/store';
import VTreatmentTime from './VTreatmentTime.vue';
import { useTreatmentCalendar } from './useTreatmentCalendar';
import { computed, onMounted, ref, watch } from 'vue';

export default {
	name: 'VWeeklyTreatment',
	components: { VTreatmentTime },
	props: {
		shifts: {
			type: Object,
			required: false,
		},
		dayOrder: {
			type: Array,
			required: false,
		},
		shiftSelectionPlaceholder: {
			type: String,
			required: false,
		},
	},
	setup(props) {
		const data = ref({});
		const store = useOfflineBookingStore();
		const { updateWeekDays, onSingleModeChange } = useTreatmentCalendar(
			window.clinicWeeklyAvailabilityURL,
			onFetch
		);

		const weeks = ref({});
		const selectedWeeks = ref(new Set());

		onMounted(() => {
			if (Object.keys(store.slot).length > 0) {
				data.value = store.slot;
				Object.keys(store.slot).forEach((week) => selectedWeeks.value.add(week));
			}
		});

        /**
         * When the date range is changed clear the selected weeks "smartly".
         * Only remove the weeks that not in the range anymore.
         *
         * If the selected week isn't in the pulled data remove it.
         */
        function removeSelectedOutOfRange() {
            const weeksPulled = Object.keys(weeks.value);

            Object.keys(data.value).forEach(key => {
                if (weeksPulled.includes(key)) return;
                delete data.value[key];
                selectedWeeks.value.delete(key);
            })
        }

        /**
         * Watch for weeks (pulled data) change and clear the buttons.
         */
        watch(weeks, removeSelectedOutOfRange);

        function onDateChange(e) {
            let { to, from, singleMode } = e.detail;
            from = from?.getTime();
            to = to?.getTime();
            store.treatmentDate = {
                from,
                ...(!singleMode && { to })
            };
            updateWeekDays();
        }

		function sortByDayOrder(a, b) {
			return props.dayOrder.indexOf(a) - props.dayOrder.indexOf(b);
		}

		function sortByKey(a, b) {
			return sortByDayOrder(a.key, b.key);
		}

		const selectedWeeksOrdered = computed(() => [...selectedWeeks.value].sort(sortByDayOrder));

		const repeatOn = computed(() => {
			let status;
            const treatmentType = store.treatmentType
			const repeatOnList = Object.keys(weeks.value)
				.map((key) => {
					status = weeks.value[key];
					return {
						key,
						active: selectedWeeks.value.has(key),
                        /**
                         * If treatment type is specified use it to check the overall for that specific type (HD or HDF).
                         * Treatment type is set on pages that have a specific form for choosing the type of booking.
                         * Should be inside `values.treatmentType`.
                         *
                         * If there is no treatment type, default to checking if any of the options are true.
                         * There is no case for this on the platform, but it is a nice fallback.
                         */
						disabled: treatmentType ? !status?.overall[store.treatmentType] : Object.values(weeks.value[key]?.overall ?? {}).every((v) => !v),
					};
				})
				.sort(sortByKey);

			return repeatOnList;
		});

		function onFetch(data) {
			weeks.value = data;
		}
		function selectWeek(key) {
			if (!selectedWeeks.value.has(key)) {
				data.value[key] = {};
				selectedWeeks.value.add(key);
			} else {
				delete data.value[key];
				selectedWeeks.value.delete(key);
			}
		}

		/**
		 * Get shift for a passed day in the week.
		 *
		 * @param {string} day - Day of the week to find inside `weeks`.
		 * @returns {*[]}
		 */
		function getShiftsPeerDay(day) {
			return props.shifts?.[day] || [];
		}

		watch(
			data,
			() => {
				store.weeklyBookingDetailsValid = Object.values(data.value).every((d) => d.valid);
			},
			{ deep: true }
		);

		const hasWeeksPulled = computed(() => Object.keys(weeks.value).length > 0);

		return {
			store,
			onDateChange,
			onSingleModeChange,
			repeatOn,
			selectWeek,
			selectedWeeks,
			data,
			getShiftsPeerDay,
			weeks,
			hasWeeksPulled,
			selectedWeeksOrdered,
			getError: store.getError,
		};
	},
};
</script>

<template>
	<div>
		<slot
			name="weekly-treatment-date"
			:onDateChange="onDateChange"
			:onSingleModeChange="onSingleModeChange"
		></slot>
		<!-- errors -->
		<div class="mb-32">
			<div class="input-error" v-if="getError('from')">{{ getError('from') }}</div>
			<div class="input-error" v-if="getError('to')">{{ getError('to') }}</div>
			<div class="input-error" v-if="getError('date')">{{ getError('date') }}</div>
		</div>
		<!-- ./errors -->
		<div class="group-small">
			<button
				type="button"
				v-for="week in repeatOn"
				:key="week.key"
				class="day-filter-button"
				:class="{ active: week.active }"
				@click="selectWeek(week.key)"
				:disabled="week.disabled"
			>
				<slot :name="`week-button-${week.key}`">{{ week.key }}</slot>
			</button>
		</div>
		<div v-if="selectedWeeks.size && hasWeeksPulled">
			<v-treatment-time
				class="treatment-time-group"
				v-for="week in selectedWeeksOrdered"
				:key="week"
				:shifts="getShiftsPeerDay(week)"
				:shift-error="getError(`slot.${week}.shift`)"
				:start-error="getError(`slot.${week}.start`)"
				:end-error="getError(`slot.${week}.end`)"
				:shift-name="`slot[${week}][shift]`"
				:start-name="`slot[${week}][start]`"
				:end-name="`slot[${week}][end]`"
				:selected-shift="data[week].shift || ''"
				:placeholder="shiftSelectionPlaceholder"
				v-model="data[week]"
			>
				<template v-for="(_, slot) of $slots" v-slot:[slot]="scope">
					<slot :name="slot" v-bind="scope"></slot>
				</template>
				<template #title><slot :name="`week-title-${week}`"></slot></template>
			</v-treatment-time>
		</div>
	</div>
</template>
