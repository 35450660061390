import { Slider } from './Slider';

const SELECTORS = {
	slider: '[data-slider-element]',
};

/**
 * Gets all elements whit `data-slider-element` attribute and
 * initialize them like Slider component.
 */
export class SliderGroup {
	constructor(sliders, options = {}) {
		this.sliderGroup = sliders || [...document.querySelectorAll(SELECTORS.slider)];

		this.sliderGroup.forEach((slider) => new Slider(slider, options));
	}

	static init() {
		// eslint-disable-next-line no-new
		new SliderGroup();
	}
}
