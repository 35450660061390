import { MOBILE_MEDIA_QUERY } from '../../common/components/utils/constants';

const SELECTORS = {
	root: '.dropdown',
	trigger: '[data-toggle="dropdown"]',
	item: '.dropdown-cl__item input',
	menu: '.dropdown-menu',
	badge: '[data-type="badge"]',
	icon: '[data-type="icon"]',
	clear: '[data-type="reset-cl-dropdown"]',
};

const CLASSES = {
	active: 'active',
	selected: 'selected',
	displayNone: 'display--none',
};

export class DropdownCheckList {
	constructor(element) {
		this.toggle = element;
		this.root = this.getRootElement();
		this.items = [...this.root.querySelectorAll(SELECTORS.item)];
		this.menu = this.root.querySelector(SELECTORS.menu);
		this.badge = this.root.querySelector(SELECTORS.badge);
		this.icon = this.root.querySelector(SELECTORS.icon);
		this.clear = this.root.querySelector(SELECTORS.clear);

		const requiredElements = [
			this.toggle,
			this.root,
			this.items,
			this.menu,
			this.badge,
			this.icon,
			this.clear,
		];
		if (requiredElements.some((ele) => !ele)) {
			throw new Error('DropdownCheckList not initialized');
		}

		/**
		 * If a user closes the dropdown without any action(clear, select),
		 * component will use `initState` to return to its initial state.
		 *
		 * @type {{items: {}}}
		 */
		this.initState = {};
		this.items.forEach((item) => {
			this.initState = { ...this.initState, [item.name]: item.checked };
		});

		this.mediaQuery = window.matchMedia(MOBILE_MEDIA_QUERY);

		this.bindEvents();
		this.setup();
	}

	static init() {
		([...document.querySelectorAll(SELECTORS.trigger)] || [])
			.filter((dropdown) => dropdown.dataset.type === 'dropdown-check-list')
			.forEach((dropdown) => new DropdownCheckList(dropdown));
	}

	bindEvents() {
		['onMenuToggle', 'onChangeCheckboxItems', 'onResetCheckboxItems'].forEach((e) => {
			this[e] = this[e].bind(this);
		});
	}

	getRootElement() {
		return this.toggle.closest(SELECTORS.root);
	}

	getCheckedItems() {
		return [...this.items].filter((checkbox) => checkbox.checked) || [];
	}

	/**
	 * Is mobile media query met.
	 * @return {boolean}
	 */
	isMobile() {
		return this.mediaQuery.matches;
	}

	/**
	 * Return dropdown to initial state when dropdown menu closes without action(clear, save)
	 */
	onMenuToggle() {
		const config = {
			attributes: true,
			attributeFilter: ['aria-expanded'],
			childList: false,
			characterData: false,
		};

		const observer = new MutationObserver(([mutation]) => {
			const showMenu = mutation.target.classList?.contains(CLASSES.active);

			if (showMenu) {
				return;
			}

			this.items.forEach((item) => {
				// eslint-disable-next-line no-param-reassign
				item.checked = this.initState[item.name];
			});
			this.onChangeCheckboxItems();

			if (this.getCheckedItems().length) {
				this.toggle.classList.add(CLASSES.selected);
			}
		});

		observer.observe(this.toggle, config);
	}

	onChangeCheckboxItems() {
		const checkedCount = this.getCheckedItems().length;
		this.toggle.classList.remove(CLASSES.selected);

		if (checkedCount > 0) {
			this.icon.classList.add(CLASSES.displayNone);
			this.badge.classList.remove(CLASSES.displayNone);
			this.badge.innerText = checkedCount;
		} else {
			this.icon.classList.remove(CLASSES.displayNone);
			this.badge.classList.add(CLASSES.displayNone);
		}
	}

	onResetCheckboxItems() {
		this.items.forEach((item) => {
			// eslint-disable-next-line no-param-reassign
			item.checked = false;
		});
		this.onChangeCheckboxItems();
	}

	setupEventListeners() {
		this.menu.addEventListener('change', this.onChangeCheckboxItems);
		this.clear.addEventListener('click', this.onResetCheckboxItems);
		this.onMenuToggle();
	}

	setup() {
		this.setupEventListeners();
	}
}
