<script>
import VUploadItem from './VUploadItem';
import { computed, ref } from 'vue';
import { UploadItem } from '../models/UploadItem.model';
import { FILE_ACCEPT_DEFAULT } from '../../common/components/utils/constants';
import { convertBytesToMB } from '../utils/convertBytesToMB';

/**
 *
 * @version 1.0.0
 * @since
 */
export default {
	name: 'VUploadList',
	components: { VUploadItem },
	props: {
		uploadUrl: {
			required: true,
			type: String,
		},
		uploadedList: {
			required: false,
			type: Array,
			default: () => [],
		},
		/**
		 * This prop is made so default values can be set.
		 * But you can still use `accept` the same way you would for `input`
		 * If you set both `accept` & `acceptList`, `accept` has bigger priority.
		 */
		acceptList: {
			required: false,
			type: Array,
			default: () => [...FILE_ACCEPT_DEFAULT],
		},
	},
	setup(props, { attrs }) {
		/*************************************************
		 * State
		 **************************************************/
		// TODO: Change with `randomString()` function when everything is merged.
		const id = Math.random().toString(36).substring(2, 9);
		const uploadList = ref(props.uploadedList.map((item) => new UploadItem({ ...item })));
		const accept = computed(() => attrs.accept || props.acceptList.join(', '));
		const acceptLabel = computed(() => accept.value.replaceAll('.', ''));

		/*************************************************
		 * DOM Event Callbacks
		 **************************************************/
		const onChange = async (event) => {
			for (const file of event.target.files) {
				const { name, size, type } = file;

				const newLength = uploadList.value.push(
					new UploadItem({
						filename: name,
						size: `${convertBytesToMB({ size })} MB`,
						type,
						isLoading: true,
					})
				);
				const fileIndex = newLength - 1;

				const formData = new FormData();
				formData.append('file', file);

				try {
					const {
						data: { data },
					} = await http.post(props.uploadUrl, formData, {
						headers: { 'Content-Type': 'multipart/form-data' },
					});
					uploadList.value[fileIndex] = new UploadItem({ ...data, isLoading: false });
				} catch (err) {
					console.error(err);
					const message = err.response.data.message || window.somethingWentWrongMsg;
					uploadList.value[fileIndex].error = message;
					uploadList.value[fileIndex].isLoading = false;
					uploadList.value[fileIndex].enableLocalDelete = true;
				}
			}
		};

		const onDeleteItem = async ({ index, item }) => {
			if (item.enableLocalDelete) {
				uploadList.value.splice(index, 1);
				return;
			}

			try {
				await http.delete(item.deleteUrl);
				uploadList.value.splice(index, 1);
			} catch (err) {
				console.error(err);
				const message = err.response.data.message || window.somethingWentWrongMsg;
				item.error = message;
			}
		};

		const downloadItem = ( index ) => {
			const item = props.uploadedList[index.index],
				source = item.downloadUrl,
				fileName = item.filename,
				element = document.createElement("a");
			
			element.setAttribute("href", source);
			element.setAttribute("download", fileName);
			document.body.appendChild(element);
			element.click();
			element.remove();
		}

		return {
			// State
			id,
			uploadList,
			accept,
			acceptLabel,

			// DOM Events
			onChange,
			onDeleteItem,
			downloadItem
		};
	},
};
</script>

<template>
	<div>
		<v-upload-item
			v-for="(item, index) in uploadList"
			:key="item.filename"
			:name="item.filename"
			:delete-url="item.deleteUrl"
			:enable-local-delete="item.enableLocalDelete"
			:info-list="item.infoList"
			:error="item.error"
			:is-loading="item.isLoading"
			@downloadItem="downloadItem({ index })"
			@deleteItem="onDeleteItem({ index, item })"
		/>

		<label
			class="upload-document-button button button--secondary button--small button--block mt-24"
			:for="id"
		>
			<slot name="trigger-content"></slot>
		</label>
		<input
			class="display--none"
			type="file"
			:id="id"
			v-bind="$attrs"
			:accept="accept"
			@change="onChange"
		/>
		<p class="mb-0 mt-16">
			<slot name="upload-info" :value="{ accept, label: acceptLabel }"> </slot>
		</p>
	</div>
</template>
