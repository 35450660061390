import { LAPTOP_MEDIA_QUERY } from '../../common/components/utils/constants';
import { debounce } from '../../common/components/utils/utils';

const SELECTORS = {
	header: '#header',
	nav: '#nav',
	searchTrigger: '[data-trigger="search"]',
	navigationTrigger: '[data-trigger="navigation"]',
	hideInMobileMode: '[data-nav="mobile--hide"]',
	searchInputGroups: `[data-nav="search-input"]`,
};

const CLASSES = {
	openSearch: 'navigation--search-open',
	openNavigation: 'navigation--open',
	inputGroupMobile: 'navigation__input-group--mobile',
	displayNone: 'display--none',
};

export const HEADER_MAIN_SELECTOR = SELECTORS.header;

export const HEADER_CSS_VARIABLE = '--site-header-height';

export class Navigation {
	constructor() {
		this.navElement = document.querySelector(SELECTORS.nav);
		this.searchTrigger = document.querySelector(SELECTORS.searchTrigger); // Opens search inputs
		this.headerElement = document.querySelector(SELECTORS.header);
		this.navigationTrigger = document.querySelector(SELECTORS.navigationTrigger);
		this.hideInMobileMode = [...document.querySelectorAll(SELECTORS.hideInMobileMode)];
		this.searchInputGroups = [...document.querySelectorAll(SELECTORS.searchInputGroups)];

		const requiredElements = [this.headerElement];
		if (requiredElements.some((ele) => !ele)) {
			return;
		}

		this.mediaQuery = window.matchMedia(LAPTOP_MEDIA_QUERY);

		if (this.isMobile()) {
			this.hideOnlyDesktopElements();
			this.addMobileClassesInputGroups();
		} else {
			this.showOnlyDesktopElements();
			this.removeMobileClassesInputGroups();
		}

		this.bindEvents();
		this.setup();
	}

	static init() {
		// eslint-disable-next-line no-unused-vars
		const navigation = new Navigation();
	}

	/**
	 * Bind `this` explicitly for event listeners.
	 */
	bindEvents() {
		[
			'onNavigationTrigger',
			'onSearchTrigger',
			'onMediaQueryChange',
			'calculateHeaderHeight',
		].forEach((e) => {
			this[e] = this[e].bind(this);
		});
	}

	/**
	 * Is mobile media query met.
	 * @return {boolean}
	 */
	isMobile() {
		return this.mediaQuery.matches;
	}

	isOpen() {
		return (
			this.navElement?.classList.contains(CLASSES.openNavigation) ||
			this.navElement?.classList.contains(CLASSES.openSearch)
		);
	}

	onNavigationTrigger() {
		this.navElement.classList.remove(CLASSES.openSearch);
		this.navElement.classList.toggle(CLASSES.openNavigation);
	}

	onSearchTrigger() {
		this.navElement.classList.remove(CLASSES.openNavigation);
		this.navElement.classList.toggle(CLASSES.openSearch);
	}

	calculateHeaderHeight() {
		// Don't update on mobile when the navigation opens.
		// The whole site will jump.
		if (this.isMobile() && this.isOpen()) return;

		const headerHeight = this.headerElement.clientHeight;
		const root = document.documentElement;

		root.style.setProperty(HEADER_CSS_VARIABLE, `${headerHeight}px`);
	}

	removeMobileClassesInputGroups() {
		this.searchInputGroups.forEach((ele) => ele.classList.remove(CLASSES.inputGroupMobile));
	}

	addMobileClassesInputGroups() {
		this.searchInputGroups.forEach((ele) => ele.classList.add(CLASSES.inputGroupMobile));
	}

	hideOnlyDesktopElements() {
		this.hideInMobileMode.forEach((ele) => {
			ele.classList.add(CLASSES.displayNone);
		});
	}

	showOnlyDesktopElements() {
		this.hideInMobileMode.forEach((ele) => {
			ele.classList.remove(CLASSES.displayNone);
		});
	}

	/**
	 * When the media query changes to desktop remove all mobile classes.
	 * @param {MediaQueryListEvent} e
	 */
	onMediaQueryChange(e) {
		if (!e.matches) {
			this.destroy();
			this.showOnlyDesktopElements();
			this.removeMobileClassesInputGroups();
		} else {
			this.hideOnlyDesktopElements();
			this.setupEventListeners();
			this.addMobileClassesInputGroups();
		}
	}

	setupEventListeners() {
		this.searchTrigger?.addEventListener('click', this.onSearchTrigger);
		this.navigationTrigger?.addEventListener('click', this.onNavigationTrigger);

		/**
		 * Watch for size changes to update the height.
		 * This will capture window resize (width) and DOM removal (height).
		 * @type {ResizeObserver}
		 */
		this.headerResizeObserver = new ResizeObserver(this.onResize);
		this.headerResizeObserver.observe(this.headerElement);
	}

	setup() {
		/**
		 * Debounce resize event
		 */
		this.onResize = debounce(this.calculateHeaderHeight, 300);

		this.mediaQuery.addEventListener('change', this.onMediaQueryChange);
		this.calculateHeaderHeight();
		this.setupEventListeners();
	}

	destroy() {
		this.navElement?.classList.remove(CLASSES.openSearch);
		this.navElement?.classList.remove(CLASSES.openNavigation);

		this.searchTrigger?.removeEventListener('click', this.onSearchTrigger);
		this.navigationTrigger?.removeEventListener('click', this.onNavigationTrigger);
		this.headerResizeObserver.disconnect();
	}
}
