<script>
import {computed, nextTick, ref} from 'vue';
import {dateFormatForAPI} from '../../common/components/utils/dateFormatForAPI';
import { DialysisType } from '../../common/components/utils/constants';

export default {
    setup() {
        /**
         * If the API data is missing.
         * This is true:
         *  1. At the start, before the date is selected
         *  2. When the date is changed while the data is pulled (loading state).
         *
         * @type {Ref<UnwrapRef<boolean>>}
         */
        const missingData = ref(true);
        const buttonDisabled = computed(() => missingData.value || !selectedShift.value)

        /*************************************************
         * Date
         **************************************************/

        const selectedDate = ref({});

        async function fetchShiftsData() {
            missingData.value = true;

            const { from, to } = selectedDate.value;
            try {
                const {data: {data}} = await http.get(window.getClinicShiftConstraintUrl, {
                    params: {
                        from: dateFormatForAPI(from),
                        to: dateFormatForAPI(to),
                    },
                });
                shiftsData.value = data;
                updateSlotData(DialysisType.HD);
                updateSlotData(DialysisType.HDF);

            } catch (e) {
                console.error(e);
            } finally {
                missingData.value = false;
            }
        }

        function onDateChange(e) {
            const { to: currentTo, from: currentFrom} = selectedDate.value
            let { to, from } = e.detail;
            to = to.getTime();
            from = from.getTime();

            // If any of the dates are different update.
            if (to !== currentTo || from !== currentFrom) {
                selectedDate.value = { from, to }
                fetchShiftsData();
            }
        }

        /*************************************************
         * Shift
         **************************************************/

        /**
         * Data pulled from API.
         *
         * @type {Ref<UnwrapRef<{}>>}
         */
        const shiftsData = ref({});
        const selectedShift = ref(null);
        const selectedShiftId = computed(() => selectedShift.value?.id);
        const selectedShiftData = computed(() => shiftsData.value?.[selectedShiftId.value] || {});

        function onShiftChange(e) {
            selectedShift.value = e;
            slotValues.value = {};
            updateSlotData(DialysisType.HD);
            updateSlotData(DialysisType.HDF);
        }


        /*************************************************
         * Slots
         **************************************************/

        const slotValues = ref({});
        /** Disable slots if time and shift are not filled */
        const disableSlots = computed(() => !selectedShift.value || missingData.value)
        const hdSlot = ref({
            disabled: disableSlots.value,
            min: 0,
            max: 0,
            value: 0,
        });
        const hdfSlot = ref({
            disabled: disableSlots.value,
            min: 0,
            max: 0,
            value: 0,
        });

        async function updateSlotData(type, value) {
            await nextTick(); // Wait for computed values to update
            if (!selectedShiftData.value.hasOwnProperty(type)) return;
            const { [type]: { min, max }} = selectedShiftData.value;
            // Object to update base on type
            const toUpdate = type === DialysisType.HD ? hdSlot : hdfSlot;
            toUpdate.value = {
                disabled: disableSlots.value || (!min && !max),
                min,
                max,
                value: value ?? min,
            }
        }

        function updateSlot(type, value) {
            slotValues.value[type] = value;
            updateSlotData(type, value);
        }

        /*************************************************
         * Export
         **************************************************/

        return {
            hdSlot,
            hdfSlot,
            disableSlots,
            date: selectedDate,
            selectedShiftId,
            selectedShiftData,
            shiftsData,
            slotValues,
            buttonDisabled,
            updateSlot,
            onShiftChange,
            onDateChange,
        }
    }
}
</script>

<template>
    <div>
        <slot
            :onShiftChange="onShiftChange"
            :disableSlots="disableSlots"
            :hdSlot="hdSlot"
            :hdfSlot="hdfSlot"
            :updateSlot="updateSlot"
            :onDateChange="onDateChange"
            :buttonDisabled="buttonDisabled"/>
    </div>
</template>
