import { rAF } from '../../common/components/utils/utils';

const SELECTORS = {
	accordionContainer: '.accordion',
	accordionItem: '.accordion__item',
	accordionItemHeader: '.accordion__item-header',
	accordionPanel: '.accordion__item-panel',
};

const CLASSES = {
	active: 'active',
};

export class Accordion {
	constructor() {
		this.accordionContainers = document.querySelectorAll(SELECTORS.accordionContainer);

		const requiredElements = [...this.accordionContainers];

		if (requiredElements.some((el) => !el)) {
			return;
		}

		this.setup();
	}

	static showElement(element) {
		const panel = element.querySelector(SELECTORS.accordionPanel);
		element.classList.add(CLASSES.active);
		element.setAttribute('aria-expanded', 'true');
		panel.removeAttribute('aria-hidden');
		const height = panel.scrollHeight;

		rAF(() => {
			panel.style.height = `${height}px`;
		});
	}

	static showElementOnLoad() {
		const allAccordionItems = document.querySelectorAll(SELECTORS.accordionItem);

		allAccordionItems.forEach((el) => {
			if (el.classList.contains('active')) {
				const activePanel = el.querySelector(SELECTORS.accordionPanel);

				el.setAttribute('aria-expanded', 'true');
				activePanel.removeAttribute('aria-hidden');
				const height = activePanel.scrollHeight;

				rAF(() => {
					activePanel.style.height = `${height}px`;
				});
			}
		});
	}

	static hideElement(element) {
		const panel = element.querySelector(SELECTORS.accordionPanel);

		element.classList.remove(CLASSES.active);
		element.removeAttribute('aria-expanded');
		panel.setAttribute('aria-hidden', 'true');

		rAF(() => {
			panel.style.height = 0;
		});
	}

	// eslint-disable-next-line class-methods-use-this
	handleClick(e) {
		const header = e.target.closest(SELECTORS.accordionItemHeader);
		if (!header) return;

		const selectedItem = header.parentElement;
		const itemActive = selectedItem.classList.contains(CLASSES.active);

		const accordion = header.closest(SELECTORS.accordionContainer);
		const accordionItems = accordion.querySelectorAll(SELECTORS.accordionItem);
		accordionItems.forEach((item) => Accordion.hideElement(item));

		if (itemActive) return;

		Accordion.showElement(selectedItem);

		Accordion.trackAccordionItemExpand(
			accordion.dataset.trackingEvent,
			selectedItem.dataset.trackingItem
		);
	}

	static trackAccordionItemExpand(eventName, itemName) {
		if (!eventName || !itemName) return;

		window.dataLayer.push({
			event: eventName,
			item_name: itemName,
		});
	}

	static init() {
		// eslint-disable-next-line no-unused-vars
		const accordion = new Accordion();
	}

	/**
	 * Setup all event listeners used by the component.
	 */
	setupEventListeners() {
		this.accordionContainers.forEach((container) =>
			container.addEventListener('click', this.handleClick)
		);
		window.addEventListener('load', Accordion.showElementOnLoad);
	}

	setup() {
		this.setupEventListeners();
	}
}
