import { Tab } from './Tab';

const SELECTORS = {
	tabs: '[data-tabs]',
};

export class Tabs {
	constructor(options) {
		this.tabs = [...document.querySelectorAll(SELECTORS.tabs)];

		const requiredElements = [this.tabs];
		if (requiredElements.some((ele) => !ele)) {
			return;
		}
		this.config = Tabs._combineOptions(options);

		this.setup();
	}

	static _combineOptions(options) {
		const defaultOptions = {
			onChange: () => {},
		};

		return { ...defaultOptions, ...options };
	}

	static init(options) {
		// eslint-disable-next-line no-unused-vars
		const tabs = new Tabs(options);
	}

	setup() {
		this.tabs.forEach((tab) => {
			// eslint-disable-next-line no-new
			new Tab(tab, this.config);
		});
	}
}
