export class UploadItem {
	constructor({
		filename,
		size,
		type,
		error = '',
		deleteUrl = '',
		isLoading = false,
		enableLocalDelete = false,
	}) {
		this.filename = filename;
		this.type = type.split('/').pop();
		this.size = size;
		this.error = error;
		this.infoList = [this.type, this.size];
		this.deleteUrl = deleteUrl;
		this.isLoading = isLoading;
		this.enableLocalDelete = enableLocalDelete;
	}
}
