<script>
import VSelectSearch from '../VSelectSearch';
import {useOfflineBookingStore} from '../../../common/components/offline-booking/store';
import {unref} from "vue";

export default {
    components: {VSelectSearch},
    props: {
        selected: {
            type: [Object, Number],
            required: false,
        },
        name: {
            type: String,
            required: false,
            default: 'patient',
        },
        patients: {
            type: Array,
            required: false,
        },
        placeholder: {
            type: String,
            required: false,
            default: 'Search',
        },
    },
    emits: ['change'],
    setup(_, { emit }) {
        const store = useOfflineBookingStore();

        /*************************************************
         * Events
         **************************************************/
        const onChange = (e) => emit('change', e);

        /**
         * Search patients by name and label.
         * @param {string} query - User input that was passed inside the search field.
         * @param {Array} data - Data array that was passed to the select component.
         * @returns {Array} - Data returned after the custom filter
         */
        function searchPatient(query, data) {
            const d = unref(data);

            return d.filter(p => [p.name, p.label].join(' ').toLowerCase().includes(query.toLowerCase()));
        }

        return {
            searchPatient,
            // Events
            onChange,
            getError: store.getError,
        }
    }
}
</script>
<template>
    <label for="patient" class="input-label"><slot name="label-patient"/></label>

    <v-select-search :placeholder="placeholder" :data="patients" :selected="selected" id="patient" :name="name" :search="searchPatient" @change="onChange">
        <template #value="{value}">
            <div class="flex-center flex--spread mr-12">
                <span>{{ value.name }}</span>
                <span>{{ value.label }}</span>
            </div>
        </template>
        <template #item="{item}">
            <div class="flex-center flex--spread">
                <span>{{ item.name }}</span>
                <span>{{ item.label }}</span>
            </div>
        </template>
        <template #empty-state><slot name="empty-state"></slot></template>
    </v-select-search>

    <div class="input-error" v-if="getError(name)">{{ getError(name) }}</div>
</template>
