<script setup>
import { useBookingFlowStore } from '../../../common/components/offline-booking/store';

/**
 * Wrapper component that will be used to update booking amount by connecting it to the store.
 *
 * @see {VTreatmentDaysAndShifts.vue}
 */

const props = defineProps({
    price: String
})

const store = useBookingFlowStore();
</script>

<template>
    <slot :count="store.treatmentDays" :price="store.price || price" :loading="store.isLoading" />
</template>
