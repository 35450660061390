import Glide, { Breakpoints, Controls, Swipe, Images } from '@glidejs/glide/dist/glide.modular.esm';
import { getDir } from '../../common/components/utils/utils';

const sliderInstances = [];

export function onNLTabsChange(id) {
	if (id === 'newsletter-library') sliderInstances.forEach((slider) => slider.update());
}

function initNewsletterSlider() {
	const newsletterSliders = [...document.querySelectorAll('[data-slider-newsletter-library]')];

	newsletterSliders.forEach((slider) => {
		const isCarousel = slider.dataset.count > 3;

		sliderInstances.push(
			new Glide(slider, {
				type: isCarousel ? 'carousel' : 'slider',
				perView: 2.8,
				gap: 32,
				rewind: isCarousel,
				direction: getDir(),
				breakpoints: {
					600: {
						perView: 1.2,
						gap: 24,
					},
				},
			}).mount({ Controls, Breakpoints, Swipe, Images })
		);
	});
}

export function init() {
	console.info('Initializing Newsletter library');
	initNewsletterSlider();
}
