import { computePosition, flip, shift, offset, arrow } from '@floating-ui/dom';
import { SelectorEngine } from '../utils/SelectorEngine';

/**
 * Tooltip Component
 *
 * Attributes:
 * data-tooltip="Online Booking"
 * data-tooltip-trigger="click"
 * data-tooltip-placement="top"
 */

const SELECTORS = {
	trigger: '[data-tooltip]:not([class*="bookdialysis-search-results-tooltip"])',
};

const CLASSES = {
	tooltip: 'tooltip',
	tooltipArrow: 'tooltip__arrow',
};

const ATTRIBUTES = {
	trigger: 'data-tooltip-trigger',
	placement: 'data-tooltip-placement',
};

const DEFAULT_TRIGGER_TYPE = 'hover'; // or click
const DEFAULT_PLACEMENT = 'bottom'; // or click

const ARROW_SIZE = 22; // in px;

export class Tooltip {
	constructor(trigger) {
		this.tooltipTrigger = SelectorEngine.getElement(trigger);

		this.bindEvents();
		this.setup();
	}

	static init() {
		const tooltips = Array.from(document.querySelectorAll(SELECTORS.trigger)) || [];

		if (tooltips.length > 0) {
			tooltips.forEach((tooltip) => new Tooltip(tooltip));
		}
	}

	/**
	 * Bind `this` explicitly for event listeners.
	 */
	bindEvents() {
		['handleClick', 'showTooltip', 'hideTooltip', 'handleClickOutside'].forEach((e) => {
			this[e] = this[e].bind(this);
		});
	}

	renderTooltip() {
		const content = this.tooltipTrigger.dataset.tooltip;
		this.tooltipElement = document.createElement('div');
		const tooltipArrowElement = document.createElement('div');

		this.tooltipElement.classList.add(CLASSES.tooltip);
		this.tooltipElement.innerHTML = content;

		tooltipArrowElement.classList.add(CLASSES.tooltipArrow);

		this.tooltipElement.appendChild(tooltipArrowElement);

		document.body.appendChild(this.tooltipElement);

		computePosition(this.tooltipTrigger, this.tooltipElement, {
			placement: this.placement,
			middleware: [
				offset(ARROW_SIZE),
				flip(),
				shift({ padding: 5 }),
				arrow({ element: tooltipArrowElement }),
			],
		}).then(({ x, y, placement, middlewareData }) => {
			Object.assign(this.tooltipElement.style, {
				left: `${x}px`,
				top: `${y}px`,
			});

			const { x: arrowX, y: arrowY } = middlewareData.arrow;

			const staticSide = {
				top: 'bottom',
				right: 'left',
				bottom: 'top',
				left: 'right',
			}[placement.split('-')[0]];

			const rotationMatrix = {
				top: '135deg',
				right: '225deg',
				bottom: '315deg',
				left: '45deg',
			}[placement.split('-')[0]];

			Object.assign(tooltipArrowElement.style, {
				left: arrowX != null ? `${arrowX}px` : '',
				top: arrowY != null ? `${arrowY}px` : '',
				right: '',
				bottom: '',
				[staticSide]: `-${ARROW_SIZE / 2 + 1}px`,
				transform: `rotate(${rotationMatrix})`,
			});
		});
	}

	showTooltip() {
		if (this.isVisble) return;

		this.renderTooltip();
		this.isVisble = true;
	}

	hideTooltip() {
		if (!this.isVisble) return;

		this.tooltipElement.remove();
		this.isVisble = false;
	}

	handleClick(e) {
		e.stopPropagation();
		return this.isVisble ? this.hideTooltip() : this.showTooltip();
	}

	handleClickOutside(e) {
		if (this.isVisble && this._isOutside(e.target)) {
			this.hideTooltip();
		}
	}

	setupEventListeners() {
		if (this._isTriggerClick()) {
			document.addEventListener('click', this.handleClickOutside);
			this.tooltipTrigger.addEventListener('click', this.handleClick);
		}

		if (this._isTriggerHover()) {
			[
				['mouseenter', this.showTooltip],
				['mouseleave', this.hideTooltip],
				['focus', this.showTooltip],
				['blur', this.hideTooltip],
			].forEach(([event, listener]) => {
				this.tooltipTrigger.addEventListener(event, listener);
			});
		}
	}

	setup() {
		this.triggerType = this._getTriggerType();
		this.placement = this._getPlacement();
		this.isVisble = false;
		this.setupEventListeners();
	}

	destroy() {
		if (this._isTriggerClick()) {
			document.removeEventListener('click', this.handleClickOutside);
			this.tooltipTrigger.removeEventListener('click', this.handleClick);
		}

		if (this._isTriggerHover()) {
			[
				['mouseenter', this.showTooltip],
				['mouseleave', this.hideTooltip],
				['focus', this.showTooltip],
				['blur', this.hideTooltip],
			].forEach(([event, listener]) => {
				this.tooltipTrigger.removeEventListener(event, listener);
			});
		}
	}

	_isTriggerClick() {
		return this.triggerType === 'click';
	}

	_isTriggerHover() {
		return this.triggerType === 'hover';
	}

	_getPlacement() {
		return this.tooltipTrigger.getAttribute(ATTRIBUTES.placement) || DEFAULT_PLACEMENT;
	}

	_getTriggerType() {
		return this.tooltipTrigger.getAttribute(ATTRIBUTES.trigger) || DEFAULT_TRIGGER_TYPE;
	}

	_isOutside(element) {
		return !this.tooltipElement.contains(element);
	}
}
