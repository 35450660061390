import { CalendarCustom } from '../../common/components/CalendarCustom';

const SELECTORS = {
	dateInputs: '[data-rangepicker]',
};

/**
 *  Selects all Calendars with `data-rangepicker` selector
 *  inits them & sets up default & parsed options & behaviour.
 */
export class CalendarGroup {
	constructor(dateInputs, customOptions = {}) {
		this.dateInputs = dateInputs || [...document.querySelectorAll(SELECTORS.dateInputs)];

		this.group = [];
		this.dateInputs.forEach((dateInput) => {
			this.group.push(new CalendarCustom(dateInput, customOptions));
		});
	}

	static init() {
		// eslint-disable-next-line no-new
		new CalendarGroup(null);
	}
}
