<script>
import { useOfflineBookingStore } from '../../../common/components/offline-booking/store';
import VPatientDetails from './VPatientDetails.vue';
import VBookingDetails from './VBookingDetails.vue';
import {hydrateState} from './hydrateState';
import {mergeDeep} from '../../../common/components/utils/utils';

export default {
    name: 'VOfflineBooking',
    components: {
        VPatientDetails,
        VBookingDetails,
    },
    props: {
        patients: {
            type: Array,
            required: false,
        },
        shifts: {
            type: Object,
            required: false,
        },
        dayOrder: {
          type: Array,
          required: false,
          default: ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'],
        },
        patientsName: {
            type: String,
            required: false,
            default: 'patient_id',
        },
        patientsPlaceholder: {
            type: String,
            required: false,
            default: 'Search',
        },
        shiftSelectionPlaceholder: {
			type: String,
			required: false,
		},
    },
    setup() {
        /*************************************************
         * State
         **************************************************/
        const store = useOfflineBookingStore();

        store.$state = mergeDeep(store.$state, hydrateState());

        /*************************************************
         * Events
         **************************************************/
        const onChangePatient = (patient) => store.patient = patient;
        const onChangeTreatmentType = (type) => (store.treatmentType = type);

        return {
            // State
            store,

            // Events
            onChangePatient,
            onChangeTreatmentType
        }
    }
}
</script>

<template>
    <div>
        <div class="split-section">
            <div class="split-section__info">
                <h2 class="heading-3"><slot name="title-patient"/></h2>
            </div>
            <div class="split-section__content">
                <slot name="section-patient">
                    <v-patient-details
                        :patients="patients"
                        :name="patientsName"
                        :placeholder="patientsPlaceholder"
                        :selected="store.patient"
                        @change="onChangePatient"
                    >
                        <template v-for="(_, slot) of $slots" v-slot:[slot]="scope"><slot :name="slot" v-bind="scope"/></template>
                    </v-patient-details>
                </slot>
            </div>
        </div>

        <div class="split-section">
            <div class="split-section__info">
                <h2 class="heading-3"><slot name="title-documentation"/></h2>
            </div>
            <div class="split-section__content">
                <slot name="section-documentation" :selected="store.documentation"/>
            </div>
        </div>

        <div v-if="$slots['section-treatment-type']" class="split-section">
            <div class="split-section__info">
                <h2 class="heading-3"><slot name="title-treatment-type" /></h2>
            </div>
            <div class="split-section__content">
                <slot
                    name="section-treatment-type"
                    :selected="store.treatmentType"
                    :onChangeTreatmentType="onChangeTreatmentType"
                />
            </div>
        </div>

        <div class="split-section">
            <div class="split-section__info">
                <h2 class="heading-3"><slot name="title-booking"/></h2>
            </div>
            <div class="split-section__content">
                <slot name="section-booking-details">
                    <v-booking-details :shifts="shifts" :dayOrder="dayOrder" :placeholder="shiftSelectionPlaceholder">
                        <template v-for="(_, slot) of $slots" v-slot:[slot]="scope"><slot :name="slot" v-bind="scope"/></template>
                    </v-booking-details>
                </slot>
            </div>
        </div>
        <div class="split-section split-section--plain">
            <div class="split-section__info"></div>
            <div class="split-section__content button-group">
                <slot name="buttons" :isFormValid="store.isFormValid"/>
            </div>
        </div>
    </div>
</template>
