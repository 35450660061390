import { mergeDeep } from '../../../common/components/utils/utils';

const DATA_OBJECT = window.values;

/**
 * Based on the passed values parse and fill the store with data.
 *
 * The values will be passed inside PHP script tag, Vue will pick them up
 * onMount and hydrate the store.
 * @returns {{}}
 */
export function hydrateState() {
	if (Object.keys(window?.values ?? {}).length === 0) return {};
	let parsedObject = {};
	Object.keys(DATA_OBJECT).forEach((key) => {
		let value;
		switch (key) {
			case 'patient':
				value = { patient: Number(DATA_OBJECT[key]) };
				break;
			case 'documentation':
				value = { documentation: DATA_OBJECT[key] };
				break;
			case 'treatmentType':
				value = { treatmentType: DATA_OBJECT[key] };
				break;
			case 'type':
				value = { template: DATA_OBJECT[key] };
				break;
			case 'shift':
				value = { treatment: { shift: DATA_OBJECT[key] } };
				break;
			case 'start':
				value = { treatment: { start: DATA_OBJECT[key] } };
				break;
			case 'end':
				value = { treatment: { end: DATA_OBJECT[key] } };
				break;
			case 'slot':
				value = { slot: DATA_OBJECT[key] };
				break;
			default:
				value = {};
				break;
		}

		parsedObject = mergeDeep(parsedObject, value);
	});

	return parsedObject;
}
