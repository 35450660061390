import { FuzzySearchInput } from '../FuzzySearchInput';

const SELECTORS = {
	inputs: '[data-filter]',
};

export class SearchFilters {
	constructor() {
		this.inputs = [...document.querySelectorAll(SELECTORS.inputs)];

		this.group = [];
		this.inputs.forEach((input) => {
			this.group.push(new FuzzySearchInput(input));
		});
	}

	static init() {
		// eslint-disable-next-line no-new
		new SearchFilters();
	}
}
